import { Row, Col } from "react-bootstrap"
import { SectionTitle } from "@components/SectionTitle"
import { HasCardItem } from "@components/HasCardItem"

//Slick
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "components/CustomSlick"

//Test Images
import { LatestArticleModel } from "./LatestArticle.model"
import { BASE_PATH } from "@shared/services/HttpServices"
import { NoDataComponent } from "components/NoDataComponent"

export default function LatestArticle({
  dataSource,
  handleCardClick,
}: {
  dataSource: LatestArticleModel
  handleCardClick: Function
}) {
  const settings = {
    rows: 2,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          prevArrow: <PrevArrow inlineCss={{ left: "1.5rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "1.5rem" }} />,
        },
      },
    ],
  }

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor={dataSource.sectionTitle.firstColor}
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor={dataSource.sectionTitle.secondColor}
      />
      <div
        style={{
          marginLeft: "-.5rem",
          marginRight: "-.5rem",
        }}>
        {dataSource.articles && dataSource.articles.length > 0 ? (
          <Slider {...settings}>
            {dataSource.articles.map(data => (
              <div className="p-2" key={data.id}>
                <HasCardItem
                  id={data.id + ""}
                  imageUrl={`${BASE_PATH}${data.image_url}`}
                  imageAlt={data.image_alt}
                  title={`${data.article_name} ${data.article_name_2}`}
                  urlSlug={data.url_slug}
                  description={data.writer_name}
                  isImgSquare={false}
                  onClick={handleCardClick}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <NoDataComponent msg="No Articles to show" />
        )}
      </div>
    </div>
  )
}
