import { Image } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"

//Slick
import Slider from "react-slick"
import { NextRoundArrow, PrevRoundArrow } from "components/CustomSlick"
import { ArticleModel } from "@components/LatestArticle/LatestArticle.model"
import { EditorsPickModel } from "./EditorsPick.model"
import { NoDataComponent } from "components/NoDataComponent"

const EditorsPickCard = ({
  id,
  image_url,
  image_alt,
  article_name,
  article_name_2,
  writer_name,
  url_slug,
  onHandleClick,
}: ArticleModel) => {
  return (
    <div
      className="position-relative"
      style={{ cursor: "pointer" }}
      onClick={() => onHandleClick(id, url_slug)}>
      <Image
        className="w-100"
        src={image_url}
        alt={image_alt}
        style={{ borderRadius: "1rem" }}
      />
      <div
        className="text-left p-4"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}>
        {article_name && (
          <h4 className="bg-primary text-light p-2">{`${article_name} ${article_name_2}`}</h4>
        )}
        {writer_name && (
          <small className="bg-primary text-light p-2">
            by : {writer_name}
          </small>
        )}
      </div>
    </div>
  )
}

export default function EditorsPick({
  handleCardClick,
  firstTitleColor,
  editorsPick,
}: {
  handleCardClick: any
  firstTitleColor: string
  editorsPick?: EditorsPickModel
}) {
  const settings = {
    rows: editorsPick.editorsPicks.length > 4 ? 2 : 1,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevRoundArrow inlineCss={{ left: "-3rem" }} />,
    nextArrow: <NextRoundArrow inlineCss={{ right: "-3rem" }} />,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevRoundArrow inlineCss={{ left: "0" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "0" }} />,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          prevArrow: <PrevRoundArrow inlineCss={{ left: "-1.5rem" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "-1.5rem" }} />,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          prevArrow: <PrevRoundArrow inlineCss={{ left: "-2rem" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "-2rem" }} />,
        },
      },
    ],
  }

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={editorsPick.sectionTitle.firstWord}
        secondWord={editorsPick.sectionTitle.secondWord}
        firstColor={firstTitleColor}
        secondColor="warning"
      />
      {editorsPick.editorsPicks && editorsPick.editorsPicks.length > 0 ? (
        <Slider {...settings}>
          {editorsPick.editorsPicks.map(data => (
            <div className="p-2" key={data.id}>
              <EditorsPickCard
                id={data.id}
                image_url={data.image_url}
                image_alt={data.image_alt}
                article_name={data.article_name}
                article_name_2={data.article_name_2}
                writer_name={data.writer_name}
                url_slug={data.url_slug}
                onHandleClick={handleCardClick}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <NoDataComponent msg="No Articles to show" />
      )}
    </div>
  )
}
