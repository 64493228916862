import { Button, Col, Container, Row } from "react-bootstrap"
import Router from "next/router"
import Image from "next/image"
import styled from "@emotion/styled"

const BannerContainer = styled.div({
  width: "100vw",
  height: "18rem",
  position: "relative",
  "@media (min-width: 1366px)": {
    height: "21rem",
  },
  "@media (min-width: 1441px)": {
    height: "22rem",
  },
  "@media (min-width: 1641px)": {
    height: "23rem",
  },
  "@media (min-width: 1920px)": {
    height: "27rem",
  },
  "@media (min-width: 2001px)": {
    height: "34rem",
  },
})

export default function FooterRequestDemo({ bannerImg, bannerText }) {
  function redirectReqDemo() {
    Router.push("/request-demo")
  }

  return (
    <BannerContainer>
      <Image
        src={bannerImg}
        layout="fill"
        objectFit="cover"
        objectPosition="right"
      />
      <Container
        style={{
          position: "absolute",
          zIndex: "99",
          top: "50%",
          left: "50%",
          transform: "Translate(-50%, -50%)",
        }}>
        <Row>
          <Col xs="auto" md="8">
            <h4>{bannerText}</h4>
          </Col>
          <Col>
            <Button
              className="text-light float-right"
              onClick={() => redirectReqDemo()}>
              Request A Demo
            </Button>
          </Col>
        </Row>
      </Container>
    </BannerContainer>
  )
}
