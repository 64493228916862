import { Col, Container, Row } from "react-bootstrap"
import FooterLogo from "@assets/navigation-logo.svg"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import Image from "next/image"

const StyledFooterMenu = styled.ul({
  listStyleType: "none",
  margin: 0,
  padding: 0,
})

const StyledP = styled.p({
  marginTop: "1.25rem",
})

const CustomLi = styled.li({
  fontSize: "0.75rem",
  "@media (min-width: 1200px)": {
    fontSize: "1rem",
  },
})

const FooterMenuLink = ({ url, label }: { url: string; label: string }) => {
  return (
    <a
      href={url}
      className="text-light"
      style={{
        textDecoration: "none",
      }}>
      <CustomLi>{label}</CustomLi>
    </a>
  )
}

export default function FooterMain({
  slogan,
  socmedUrls,
  companyUrls,
  productUrls,
  getintouchUrls,
}) {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#252525",
          minHeight: "12.5rem",
          paddingBottom: "1.25rem",
          paddingTop: "15rem",
          position: "relative",
        }}>
        <Container>
          <Row className="row">
            <Col xs="12" lg="6">
              <div style={{ maxWidth: "146px", width: "100%" }}>
                <Image
                  src={FooterLogo.src}
                  alt={""}
                  layout="responsive"
                  width={25}
                  height={6}
                  objectFit="cover"
                />
              </div>
              <StyledP className="text-light">
                {slogan && parse(slogan)}
              </StyledP>
              <Row className="row-cols-auto">
                {socmedUrls &&
                  socmedUrls.map(data => (
                    <Col key={data.id}>
                      <div className="footer-fb-icon">
                        <a href={data.value} target="_blank" rel="noreferrer">
                          <Image
                            src={data.icon}
                            alt={""}
                            width={25}
                            height={25}
                          />
                        </a>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col>
              <StyledP className="text-light">COMPANY</StyledP>
              <StyledFooterMenu>
                {companyUrls &&
                  companyUrls.map(data => (
                    <FooterMenuLink
                      key={data.id}
                      url={data.value}
                      label={data.label}
                    />
                  ))}
              </StyledFooterMenu>
            </Col>
            <Col>
              <StyledP className="text-light">PRODUCT</StyledP>
              <StyledFooterMenu>
                {productUrls &&
                  productUrls.map(data => (
                    <FooterMenuLink
                      key={data.id}
                      url={data.value}
                      label={data.label}
                    />
                  ))}
              </StyledFooterMenu>
            </Col>
            <Col>
              <StyledP className="text-light">GET IN TOUCH</StyledP>
              <StyledFooterMenu>
                {getintouchUrls &&
                  getintouchUrls.map(data => (
                    <FooterMenuLink
                      key={data.id}
                      url={data.value}
                      label={data.label}
                    />
                  ))}
              </StyledFooterMenu>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
