import {
  Button,
  Form,
  Image,
  Nav,
  Navbar,
  NavbarBrand,
  Container,
  NavDropdown,
} from "react-bootstrap"
import { useRouter } from "next/router"

import NavigationLogo from "@assets/navigation-logo.svg"
import { css } from "@emotion/css"
import { useTheme } from "@emotion/react"

export default function Header() {
  const router = useRouter()
  const theme = useTheme()
  const loginRedirect = process.env.NEXT_PUBLIC_TORUS_WEB_HOST
    ? `${process.env.NEXT_PUBLIC_TORUS_WEB_HOST}/login`
    : "https://torus.hostastay.com/login"
  const derantauRedirect = process.env.NEXT_PUBLIC_DE_RANTAU_HOST
    ? process.env.NEXT_PUBLIC_DE_RANTAU_HOST
    : "https://booking.hostastay.com"
  const hostPlatformRedirect = process.env.NEXT_PUBLIC_HOSTPLATFORM_HOST
    ? process.env.NEXT_PUBLIC_HOSTPLATFORM_HOST
    : "https://www.hostplatform.com"
  const hostsRedirect = process.env.NEXT_PUBLIC_HAS_HOST
    ? `${process.env.NEXT_PUBLIC_HAS_HOST}/host`
    : "https://www.hostastay.com/host"
  const renoCalcRedirect = process.env.HAS_SEARCH_HOST 
    ? `${process.env.HAS_SEARCH_HOST}/reno-calculator`
    : "https://search.hostastay.com/reno-calculator" 

  return (
    <div
      style={{
        boxShadow: "0rem .25rem .375rem rgba(0,0,0,0.161)",
      }}>
      <Container className="nav-container">
        <Navbar bg="light" variant="light" expand="lg">
          <div className="d-flex align-items-center justify-content-between">
            <NavbarBrand as="a" href="/">
              <Image src={NavigationLogo.src} height="32" alt={""} />
            </NavbarBrand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav
              className={`ms-auto mb-2 mb-lg-0 me-5 ${css`
                .nav-link {
                  padding: 0 !important;
                  margin: 0.5rem;
                  border-bottom: 0.25rem solid transparent;

                  &.active {
                    color: ${theme.colors.primary}!important;
                    border-bottom-color: ${theme.colors.warning};
                  }
                }
              `}`}>
              <NavDropdown
                title="Products"
                id="navbar-dropdown"
                style={{ color: "black" }}
                align="end">
                <NavDropdown.Item href={derantauRedirect}>
                  DE Rantau
                </NavDropdown.Item>
                <NavDropdown.Item href={hostPlatformRedirect}>
                  HostPlatform
                </NavDropdown.Item>
                <NavDropdown.Item href="/investment-property">
                  Property Listing
                </NavDropdown.Item>
                <NavDropdown.Item href="/financing">Financing</NavDropdown.Item>
                <NavDropdown.Item href={renoCalcRedirect}>Reno</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href={hostsRedirect} className="text-dark">
                Host
              </Nav.Link>
              <NavDropdown title="Partners" id="navbar-dropdown" align="end">
                <NavDropdown.Item href="/has-partner">
                  HAS Partners
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/about-us"
                className={`text-dark ${
                  router.pathname == "/about-us" && "active"
                }`}>
                About
              </Nav.Link>
              <Nav.Link href={loginRedirect} className="text-dark">
                Log In
              </Nav.Link>
            </Nav>
            <Form className="d-flex">
              <Button className="text-light" href="/request-demo">
                Request A Demo
              </Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  )
}
