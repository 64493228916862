import { Row, Col, Image } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"
import styled from "@emotion/styled"
import { VisionMissionModel } from "./VisionMission.model"
import parse from "html-react-parser"
import { BASE_PATH } from "@shared/services/HttpServices"

const ImgContainer = styled.div({
  maxWidth: "21rem",
  margin: "auto",
  "@media (max-width: 991px)": {
    marginBottom: "3rem",
  },
})

export default function VisionMission({
  dataSource,
}: {
  dataSource: VisionMissionModel
}) {
  return (
    <Row className="align-items-center">
      <Col lg="4">
        <ImgContainer>
          <Image
            className="w-100"
            src={`${BASE_PATH}${dataSource.imageUrl}`}
            alt={dataSource.imageAlt}
          />
        </ImgContainer>
      </Col>
      <Col lg="8">
        <div className="text-center">
          <div className="pb-5">
            <SectionTitle
              firstWord={dataSource.ourVisionSectionTitle.firstWord}
              firstColor="light"
              secondWord={dataSource.ourVisionSectionTitle.secondWord}
            />
            {parse(dataSource.ourVisionContent)}
          </div>
          <div>
            <SectionTitle
              firstWord={dataSource.ourMissionSectionTitle.firstWord}
              firstColor="light"
              secondWord={dataSource.ourMissionSectionTitle.secondWord}
            />
            {parse(dataSource.ourMissionContent)}
          </div>
        </div>
      </Col>
    </Row>
  )
}
