import { Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"

const StyledSmall = styled.small(
  {
    display: "block",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

export default function PropertySpecific({
  price_range,
  management_office_location,
  built_up_size,
  listed_at,
  type,
  built_up_price,
  completion_year,
}) {
  const listDate = new Date(listed_at)
  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  }

  return (
    <div>
      <StyledSmall className="mb-2">Sale Price Range</StyledSmall>
      <h2 style={{ fontWeight: 500 }}>{price_range}</h2>
      <hr />
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        Project Details
      </h4>
      <Row>
        <Col>
          <StyledSmall>Management Office (JMB)</StyledSmall>
          <p>{management_office_location}</p>
        </Col>
        <Col>
          <StyledSmall>Project Type</StyledSmall>
          <p>{type}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledSmall>Built-up Size</StyledSmall>
          <p>{built_up_size}</p>
        </Col>
        <Col>
          <StyledSmall>Built-up Price</StyledSmall>
          <p>{built_up_price}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledSmall>Listed On</StyledSmall>
          <p>{`${listDate.getDate()} ${
            months[listDate.getMonth()]
          } ${listDate.getFullYear()}`}</p>
        </Col>
        <Col>
          <StyledSmall>Completion Year</StyledSmall>
          <p>{completion_year}</p>
        </Col>
      </Row>
    </div>
  )
}
