import { Table, Image, Button } from "react-bootstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

const StyledTable = styled(Table)(
  {
    "& th, td": {
      fontSize: ".75rem",
    },
  },
  ({ theme }) => ({
    // color: theme.colors.secondary,
  })
)

export default function PropertyTable({ unit_types, display_suite_link }) {
  const theme = useTheme()
  return (
    <div>
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        Unit Type
      </h4>
      <div className="project-unit-type-table">
        <StyledTable striped borderless>
          <thead>
            <tr className="bg-primary">
              <th className="text-center">Type</th>
              <th>Built (sq.ft)</th>
              <th>Starting Price</th>
            </tr>
          </thead>
          <tbody>
            {unit_types &&
              unit_types.map(data => (
                <tr key={data.id}>
                  <td>
                    Type {data.type} - {data.bedroom} &amp; {data.bathroom}
                  </td>
                  <td>{data.built_size}</td>
                  <td>{data.starting_price}</td>
                </tr>
              ))}
          </tbody>
        </StyledTable>
      </div>
      <div
        className="position-relative"
        style={{
          paddingBottom: "65%",
          backgroundColor: "#E6E6E6",
        }}>
        <iframe
          src={display_suite_link}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}></iframe>
        <Button
          className="position-absolute px-5 border-0 rounded-0"
          variant="light"
          style={{
            bottom: ".5rem",
            right: ".5rem",
            backgroundColor: "#C4C4C4",
          }}>
          360&#176;
        </Button>
      </div>
    </div>
  )
}
