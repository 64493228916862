import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { SectionTitle } from "components/SectionTitle"
import { CustomGreyContainer } from "layouts"
import { FC } from "react"
import { Row } from "react-bootstrap"
import { NoDataComponent } from "components/NoDataComponent"
import { HomeLoanInterestRatesModel } from "./HomeLoanInterestRates.model"

const dynamicBorder = props =>
  css`
    border-bottom: ${props.last ? "none" : `1px solid ${props.borderColor}`};
  `

const TableContentDiv: FC<TableContentDivProps> = styled.div`
  ${dynamicBorder}
`

interface TableContentDivProps {
  className?: string
  last?: boolean
  borderColor?: string
}

export default function HomeLoanInterestRates({
  dataSource,
}: {
  dataSource: HomeLoanInterestRatesModel
}) {
  const theme: any = useTheme()
  return (
    <div className="text-center py-5">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor={dataSource.sectionTitle.firstColor}
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor={dataSource.sectionTitle.secondColor}
      />
      <div className="my-2 mx-0">
        <CustomGreyContainer>
          <Row>
            <div className="col-6 text-left">
              <h4
                style={{
                  fontWeight: "700",
                }}>
                Bank & Lender Name
              </h4>
            </div>
            <div className="col-6 text-left">
              <h4
                style={{
                  fontWeight: "700",
                }}>
                Home Loan Interest Rate
              </h4>
            </div>
          </Row>
        </CustomGreyContainer>
      </div>
      <div className="mb-5 mx-0">
        <CustomGreyContainer>
          {dataSource.interest_rates && dataSource.interest_rates.length > 0 ? (
            dataSource.interest_rates.map(data => (
              <TableContentDiv
                key={data.id}
                borderColor={theme.colors.primary}
                className="row pb-2 mb-2">
                <div className="col-6 text-left">
                  <h4
                    style={{
                      fontWeight: "600",
                    }}>
                    {data.bank_lender_name}
                  </h4>
                </div>
                <div className="col-6 text-left">
                  <h4
                    style={{
                      fontWeight: "400",
                    }}>
                    {data.interest_rate}
                  </h4>
                </div>
              </TableContentDiv>
            ))
          ) : (
            <NoDataComponent msg="No Rates to show" />
          )}
        </CustomGreyContainer>
      </div>
    </div>
  )
}
