import { useTheme } from "@emotion/react"
import FinancingServices from "@shared/services/FinancingServices"
import { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { LoanFinancialCardSelected } from ".."
import CloseIcon from "@assets/close.png"
import { LoanModel } from "@shared/models/Loan.model"
import { LoanAppliedModel } from "./LoanApplied.model"

export default function LoanApplied({
  loans,
  setApplicationDone,
}: LoanAppliedModel) {
  const loanIds = loans.map(data => {
    return data.id
  })
  const theme = useTheme()
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    loan_data: loanIds,
  })

  const handleOnFormChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const [show, setShow] = useState(false)
  const handleClose = () => {
    setApplicationDone()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [btnDisabled, setBtnDisabled] = useState(false)

  const handleOnSubmit = async e => {
    e.preventDefault()
    setBtnDisabled(true)
    const res = await FinancingServices().submitLoanApplication(formState)

    if (res) {
      setBtnDisabled(false)
      handleShow()
    } else {
      setBtnDisabled(false)
      alert("Error when submitting!")
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <h2 style={{ color: theme.colors.primary }} className="mt-5 mb-4">
        Apply Loan
      </h2>
      {loans.map(data => (
        <div
          key={data.id}
          style={{
            overflowX: "scroll",
            borderRadius: "0.625rem",
            width: "100%",
          }}>
          <div
            style={{
              width: "1100px",
            }}>
            <LoanFinancialCardSelected loanData={data} />
          </div>
        </div>
      ))}
      <Form
        onSubmit={handleOnSubmit}
        style={{ width: "100%" }}
        className="d-flex flex-column align-items-center">
        <Form.Group style={{ width: "100%" }}>
          <Row className="gy-5 py-5">
            <Col xs="12" lg="6" className="text-left">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                id="first_name"
                name="first_name"
                type="text"
                value={formState.first_name}
                onChange={handleOnFormChange}
                required={true}
              />
            </Col>
            <Col xs="12" lg="6" className="text-left">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                id="last_name"
                name="last_name"
                type="text"
                value={formState.last_name}
                onChange={handleOnFormChange}
                required={true}
              />
            </Col>
            <Col xs="12" lg="6" className="text-left">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                id="email"
                name="email"
                type="email"
                value={formState.email}
                onChange={handleOnFormChange}
                required={true}
              />
            </Col>
            <Col xs="12" lg="6" className="text-left">
              <Form.Label>Phone Number*</Form.Label>
              <Form.Control
                id="phone_number"
                name="phone_number"
                type="text"
                value={formState.phone_number}
                onChange={handleOnFormChange}
                required={true}
              />
            </Col>
          </Row>
        </Form.Group>
        <Button type="submit" className="text-light" disabled={btnDisabled}>
          {btnDisabled ? "Sending..." : "Send"}
        </Button>
      </Form>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body
          className="p-5 custom-modal"
          style={{ position: "relative" }}>
          <img
            src={CloseIcon.src}
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              width: "1.5rem",
              height: "1.5rem",
              cursor: "pointer",
            }}
          />
          <h3 className="text-center">Loan Successfully submitted!</h3>
          <p className="text-center">
            Our team will contact you as soon as possible. Keep waiting!
          </p>
          <Button
            variant="primary"
            onClick={handleClose}
            className="w-100 text-light mt-3"
            style={{ borderRadius: "0.625rem" }}>
            Yeah, thanks!
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}
