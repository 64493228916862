import { Container } from "react-bootstrap"

import {
  Header,
  FooterCopyright,
  FooterMain,
  FooterRequestDemo,
} from "components"
import { BASE_PATH } from "@shared/services/HttpServices"
import { FooterModel } from "@components/Footer/Footer.model"
import styled from "@emotion/styled"

interface StandardLayoutModel {
  children: any
  footerData?: FooterModel
  error?: any
}

const FooterContainer = styled(Container)({
  position: "relative",
  padding: "0",
  "@media (min-width: 1366px)": {
    marginTop: "5rem",
  },
  "@media (min-width: 1920px)": {
    marginTop: "20rem",
  },
})

const FooterBannerContainer = styled.div({
  position: "absolute",
  zIndex: "99",
  top: "-4rem",
  "@media (min-width: 1366px)": {
    top: "-10rem",
  },
  "@media (min-width: 1441px)": {
    top: "-12rem",
  },
  "@media (min-width: 1920px)": {
    top: "-21rem",
  },
})

const StandardLayout = ({
  children,
  footerData,
  error,
}: StandardLayoutModel) => {
  if (error) {
    return <div>An error occured: {error.message}</div>
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
      <Header />
      {children}
      <FooterContainer fluid>
        <FooterBannerContainer>
          <FooterRequestDemo
            bannerImg={footerData.footer_banner}
            bannerText={footerData.footer_title}
          />
        </FooterBannerContainer>
        <FooterMain
          slogan={footerData.footer_slogan}
          socmedUrls={footerData.socmed_urls}
          companyUrls={footerData.company_urls}
          productUrls={footerData.product_urls}
          getintouchUrls={footerData.getintouch_urls}
        />
        <FooterCopyright />
      </FooterContainer>
    </div>
  )
}

export default StandardLayout
