import { Col, Row } from "react-bootstrap"
import MarketGrade from "./MarketGrade"
import RateCard from "./RateCard"

export default function MarketAnalysis({
  market_grade,
  average_daily_rate,
  occupancy_rate,
  revenue_rate,
}) {
  const marketGradeData = [
    {
      name: "Rental Demand",
      value: market_grade?.rental_demand ?? 0,
      active: market_grade?.rental_demand > 0 ? true : false,
    },
    {
      name: "Revenue Growth",
      value: market_grade?.revenue_growth ?? 0,
      active: market_grade?.revenue_growth > 0 ? true : false,
    },
    {
      name: "Seasonality",
      value: market_grade?.seasonality ?? 0,
      active: market_grade?.seasonality > 0 ? true : false,
    },
    {
      name: "Regulation",
      value: market_grade?.regulation ?? 0,
      active: market_grade?.regulation > 0 ? true : false,
    },
  ]

  const avgDailyRateData = {
    firstVal: average_daily_rate?.prev_month?.amount ?? 0,
    secondVal: average_daily_rate?.current_month?.amount ?? 0,
    firstLabel: average_daily_rate?.prev_month?.label ?? 0,
    secondLabel: average_daily_rate?.current_month?.label ?? 0,
    value: `RM ${average_daily_rate?.today}`,
    color: "#FFC20E",
  }

  const occRateData = {
    firstVal: occupancy_rate?.prev_month?.amount ?? 0,
    secondVal: occupancy_rate?.current_month?.amount ?? 0,
    firstLabel: occupancy_rate?.prev_month?.label ?? 0,
    secondLabel: occupancy_rate?.current_month?.label ?? 0,
    value: `RM ${occupancy_rate?.today}`,
    color: "#F4511E",
  }

  const revRateData = {
    firstVal: revenue_rate?.prev_month?.amount ?? 0,
    secondVal: revenue_rate?.current_month?.amount ?? 0,
    firstLabel: revenue_rate?.prev_month?.label ?? 0,
    secondLabel: revenue_rate?.current_month?.label ?? 0,
    value: `RM ${revenue_rate?.today}`,
    color: "#26A69A",
  }

  return (
    <div>
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        Market Analysis
      </h4>
      <Row className="gy-3">
        <Col xs="12" md="6" lg="3">
          <MarketGrade dataList={marketGradeData} />
        </Col>
        <Col xs="12" md="6" lg="3">
          <RateCard title="Average Daily Rate" content={avgDailyRateData} />
        </Col>
        <Col xs="12" md="6" lg="3">
          <RateCard title="Occupancy Rate" content={occRateData} />
        </Col>
        <Col xs="12" md="6" lg="3">
          <RateCard title="Revenue" content={revRateData} />
        </Col>
      </Row>
    </div>
  )
}
