import { Image } from "react-bootstrap"
import styled from "@emotion/styled"

import PrevButton from "@assets/btn_prev.png"
import NextButton from "@assets/btn_next.png"

import RoundChevron from "@assets/round-chevron.png"

const WithoutDefaultArrow = styled.div`
  width: auto;
  height: auto;
  &::before {
    content: none;
  }
  &::after {
    content: none;
  }
`

//Square blue arrow
export function PrevArrow(props) {
  const { className, inlineCss, onClick } = props
  return (
    <WithoutDefaultArrow
      className={className}
      style={{ zIndex: 1, left: 0, ...inlineCss }}
      onClick={onClick}>
      <Image
        src={PrevButton.src}
        alt={""}
        style={{
          boxShadow: ".125rem .125rem .25rem var(--bs-secondary)",
          transform: "translateX(-50%)",
        }}
      />
    </WithoutDefaultArrow>
  )
}

export function NextArrow(props) {
  const { className, inlineCss, onClick } = props
  return (
    <WithoutDefaultArrow
      className={className}
      style={{ zIndex: 1, right: 0, ...inlineCss }}
      onClick={onClick}>
      <Image
        src={NextButton.src}
        alt={""}
        style={{
          boxShadow: ".125rem .125rem .25rem var(--bs-secondary)",
          transform: "translateX(50%)",
        }}
      />
    </WithoutDefaultArrow>
  )
}
//Square blue arrow

//Circle yellow arrow
export function PrevRoundArrow(props) {
  const { className, inlineCss, onClick } = props
  return (
    <WithoutDefaultArrow
      className={className}
      style={{ zIndex: 1, ...inlineCss }}
      onClick={onClick}>
      <Image
        src={RoundChevron.src}
        style={{ transform: "rotate(180deg)" }}
        alt={""}
      />
    </WithoutDefaultArrow>
  )
}

export function NextRoundArrow(props) {
  const { className, inlineCss, onClick } = props
  return (
    <WithoutDefaultArrow
      className={className}
      style={{ zIndex: 1, ...inlineCss }}
      onClick={onClick}>
      <Image src={RoundChevron.src} alt={""} />
    </WithoutDefaultArrow>
  )
}
