import { Card } from "react-bootstrap"
import NoImg from "@assets/no-image.png"
import Image from "next/image"
import { HasCardItemModel } from "@components/HasCardItem/HasCardItem.model"

export default function VideoCardItem({
  id,
  imageUrl,
  imageAlt,
  title,
  description,
  altBg,
  urlSlug,
  onClick,
}: HasCardItemModel) {
  return (
    <Card
      style={{
        border: "0",
        borderRadius: "0",
        cursor: "pointer",
      }}
      onClick={() => onClick(id, urlSlug)}>
      <Image
        className="w-100"
        src={imageUrl ? imageUrl : NoImg.src}
        layout="responsive"
        width={45}
        height={26}
        objectFit="cover"
        alt={imageAlt}
        style={{
          borderRadius: "0",
        }}
      />

      <Card.Body
        className="text-left"
        style={{
          background: altBg && "var(--grey-bright)",
          paddingLeft: "0",
          paddingRight: "0",
        }}>
        <Card.Title as="h6">{title}</Card.Title>
        {description && <small className="text-secondary">{description}</small>}
      </Card.Body>
    </Card>
  )
}
