import { Row, Col, Button } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"
import { HasCardItem } from "../HasCardItem"

import { HASPartnerMasterlistModel } from "@components/HASPartnerMasterlist/HASPartnerMasterlist.model"
import { BASE_PATH } from "@shared/services/HttpServices"
import { NoDataComponent } from "components/NoDataComponent"

export default function HasPartners({
  dataSource,
  onClick,
  onCardClick,
}: {
  dataSource: HASPartnerMasterlistModel
  onClick: Function
  onCardClick: Function
}) {
  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor="primary"
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor="warning"
      />
      <div>
        <Row className="row-cols-2 row-cols-lg-5">
          {dataSource.has_partners && dataSource.has_partners.length > 0 ? (
            dataSource.has_partners.map(data => (
              <Col key={data.id}>
                <HasCardItem
                  id={String(data.id)}
                  imageUrl={`${BASE_PATH}${data.imageUrl}`}
                  imageAlt={data.imageAlt}
                  title={data.name}
                  urlSlug={data.url_slug}
                  description={data.description}
                  altBg={true}
                  isImgSquare={true}
                  onClick={onCardClick}
                />
              </Col>
            ))
          ) : (
            <Col xs="12" className="w-100">
              <NoDataComponent msg="No Has Partners to show" />
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <Button className="text-light" onClick={() => onClick()}>
              See more
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
