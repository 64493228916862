import { FilterModel } from "@shared/models/Filter.model"

export const projectTypeList: FilterModel[] = [
  {
    name: "Apartment",
    value: "Apartment",
  },
  {
    name: "Condominium",
    value: "Condominium",
  },
  {
    name: "Serviced Apartment",
    value: "Serviced Apartment",
  },
  {
    name: "Serviced Residence",
    value: "Serviced Residence",
  },
  {
    name: "Serviced Suites",
    value: "Serviced Suites",
  },
  {
    name: "Penthouse",
    value: "Penthouse",
  },
  {
    name: "Loft",
    value: "Loft",
  },
  {
    name: "Duplex",
    value: "Duplex",
  },
  {
    name: "SoHo",
    value: "SoHo",
  },
  {
    name: "SoFo",
    value: "SoFo",
  },
  {
    name: "SoVo",
    value: "SoVo",
  },
  {
    name: "Vos",
    value: "Vos",
  },
  {
    name: "Bungalow",
    value: "Bungalow",
  },
  {
    name: "Villa",
    value: "Villa",
  },
  {
    name: "Terrace - Single Storey",
    value: "Terrace - Single Storey",
  },
  {
    name: "Terrace - Double Storey",
    value: "Terrace - Double Storey",
  },
  {
    name: "Terrace - Three Storey",
    value: "Terrace - Three Storey",
  },
  {
    name: "Townhouse",
    value: "Townhouse",
  },
  {
    name: "Semi - D",
    value: "Semi - D",
  },
  {
    name: "Other",
    value: "Other",
  },
]

export const projectStatusList: FilterModel[] = [
  {
    name: "New Launch",
    value: "New Launch",
  },
  // {
  //   name: "Under Construction",
  //   value: "Under Construction",
  // },
  // {
  //   name: "Vacant Possession / Key Collection",
  //   value: "Vacant Possession / Key Collection",
  // },
  // {
  //   name: "Defect Check",
  //   value: "Defect Check",
  // },
  // {
  //   name: "Renovation",
  //   value: "Renovation",
  // },
  // {
  //   name: "Short Stay Setup",
  //   value: "Short Stay Setup",
  // },
  {
    name: "Live",
    value: "Live",
  },
  // {
  //   name: "Lease for Sale",
  //   value: "Lease for Sale",
  // },
  // {
  //   name: "Remove from Listing",
  //   value: "Remove from Listing",
  // },
]

export const stateList: FilterModel[] = [
  {
    name: "Johor",
    value: "Johor",
  },
  {
    name: "Kedah",
    value: "Kedah",
  },
  {
    name: "Kelantan",
    value: "Kelantan",
  },
  {
    name: "Kuala Lumpur",
    value: "Kuala Lumpur",
  },
  {
    name: "Labuan",
    value: "Labuan",
  },
  {
    name: "Melaka",
    value: "Melaka",
  },
  {
    name: "Negeri Sembilan",
    value: "Negeri Sembilan",
  },
  {
    name: "Pahang",
    value: "Pahang",
  },
  {
    name: "Penang",
    value: "Penang",
  },
  {
    name: "Perak",
    value: "Perak",
  },
  {
    name: "Perlis",
    value: "Perlis",
  },
  {
    name: "Putrajaya",
    value: "Putrajaya",
  },
  {
    name: "Sabah",
    value: "Sabah",
  },
  {
    name: "Sarawak",
    value: "Sarawak",
  },
  {
    name: "Selangor",
    value: "Selangor",
  },
  {
    name: "Terengganu",
    value: "Terengganu",
  },
]

export const tenancyTypeList: FilterModel[] = [
  {
    name: "Hybrid",
    value: "Hybrid",
  },
  {
    name: "Short Stay",
    value: "Short Stay",
  },
  {
    name: "Long Stay",
    value: "Long Stay",
  },
  {
    name: "Own Stay",
    value: "Own Stay",
  },
]
