import HTTPServices from "@shared/services/HttpServices"
import { useEffect, useState } from "react"
import { PropertyDetailModel } from "./PropertyDetail.model"

export default function GetPropertyDetail(id: string) {
  const [propertyDetail, setPropertyDetail] = useState<PropertyDetailModel>({})

  useEffect(() => {
    try {
      new HTTPServices("torus")
        .client_get(
          `/external/projects/${id}?include=images,hosts,renovation_partners,agents,additional_informations,unit_types`
        )
        .then(res => {
          const rawData = res.data.data

          return setPropertyDetail({
            name: rawData.name,
            status: rawData.status,
            address_1: rawData.address_1,
            address_2: rawData.address_2,
            post_code: rawData.post_code,
            state: rawData.state,
            description: rawData.description,
            tags: rawData.tags,
            images: rawData.images,
            price_range: rawData.price_range,
            management_office_location: rawData.management_office_location,
            built_up_size: rawData.built_up_size,
            listed_at: rawData.listed_at,
            type: rawData.type,
            built_up_price: rawData.built_up_price,
            completion_year: rawData.completion_year,
            unit_types: rawData.unit_types,
            display_suite_link: rawData.display_suite_link,
            additional_informations: rawData.additional_informations,
            lat: rawData.lat,
            lng: rawData.lng,
            market_grade: rawData.market_grade,
            average_daily_rate: rawData.average_daily_rate,
            occupancy_rate: rawData.occupancy_rate,
            revenue_rate: rawData.revenue_rate,
            hosts: rawData.hosts,
            renovation_partners: rawData.renovation_partners,
            agents: rawData.agents,
            developer: rawData.developer,
          })
        })
    } catch (err) {
      console.log(err)
    }
  }, [id])

  return {
    propertyDetail,
  }
}
