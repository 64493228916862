import Router from "next/router"

export const DetailPageRouting = (
  baseUrl: string,
  id: string,
  url_slug: string
) => {
  if (baseUrl == "investment-property") {
    url_slug = url_slug
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
    Router.push(`/${baseUrl}/${url_slug}/${id}`)
  } else {
    Router.push(`/${baseUrl}/${url_slug}`)
  }
}
