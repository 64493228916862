import { useTheme } from "@emotion/react"
import { NextArrow, PrevArrow } from "components/CustomSlick"
import { PropertyCard } from "components/PropertyCard"
import { SectionTitle } from "components/SectionTitle"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import { NoDataComponent } from "components/NoDataComponent"

export default function MightLike({ propertyList, handlePropertyClick }) {
  const theme = useTheme()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    mobileFirst: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <PrevArrow inlineCss={{ left: "2rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "2rem" }} />,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          prevArrow: <PrevArrow inlineCss={{ left: "2rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "2rem" }} />,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          prevArrow: <PrevArrow inlineCss={{ left: "2rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "2rem" }} />,
        },
      },
    ],
  }

  return (
    <div
      className="text-center pt-5"
      style={{
        backgroundColor: theme.colors.greybright,
        paddingBottom: "12rem",
      }}>
      <Container>
        <SectionTitle
          firstWord="You Might"
          firstColor="primary"
          secondWord="Like"
          secondColor="warning"
        />
        <div className="pt-5">
          {propertyList && propertyList.length > 0 ? (
            <Slider {...settings}>
              {propertyList.map(data => (
                <div className="p-2" key={`ml${data.id}`}>
                  <PropertyCard
                    id={data.id}
                    imageUrl={data.imageUrl}
                    imageAlt={data.imageAlt}
                    name={data.name}
                    location={data.location}
                    developer={data.developer}
                    tags={data.tags}
                    onCardClick={handlePropertyClick}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <NoDataComponent msg="No Properties to show" />
          )}
        </div>
      </Container>
    </div>
  )
}
