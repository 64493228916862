import { PropertyCard } from "components/PropertyCard"
import { SectionTitle } from "components/SectionTitle"
import { useEffect, useState } from "react"
import { Row, Col, Button } from "react-bootstrap"
import FilterIcon from "@assets/filter-icon.png"
import { FilterComponentModel } from "@components/FilterComp/FilterComp.model"
import { FilterComp } from "components/FilterComp"
import { FilterModel } from "@shared/models/Filter.model"
import {
  projectStatusList,
  projectTypeList,
  stateList,
  tenancyTypeList,
} from "./PropertyList.factory"
import { PropertyCardModel } from "@components/PropertyCard/PropertyCardModel"
import { NoDataComponent } from "components/NoDataComponent"

const allFilter: FilterModel = {
  name: "All",
  value: "All",
}

const initialState: FilterComponentModel[] = [
  {
    filter_name: "Property Type",
    type: "type",
    filter_value: {
      name: "All",
      value: "All",
    },
    filter_selections: [
      {
        ...allFilter,
      },
      ...projectTypeList,
    ],
  },
  {
    filter_name: "Property Status",
    type: "status",
    filter_value: {
      name: "All",
      value: "All",
    },
    filter_selections: [
      {
        ...allFilter,
      },
      ...projectStatusList,
    ],
  },
  {
    filter_name: "Location",
    type: "location",
    filter_value: {
      name: "All",
      value: "All",
    },
    filter_selections: [
      {
        ...allFilter,
      },
      ...stateList,
    ],
  },
  // {
  //   filter_name: "Tenure",
  //   type: "tenure",
  //   filter_value: {
  //     name: "All",
  //     value: "All",
  //   },
  //   filter_selections: [
  //     {
  //       ...allFilter,
  //     },
  //     ...tenancyTypeList,
  //   ],
  // },
]

export default function PropertyList({
  dataList,
  handleCardClick,
  showFilter,
}: {
  dataList: PropertyCardModel[]
  handleCardClick: any
  showFilter: any
}) {
  const [filterObj, setFilterObj] = useState(initialState)
  const [properties, setProperties] = useState(dataList)
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    const filterType = filterObj.find(filter => filter.type == "type")
      ?.filter_value.value
    const filterStatus = filterObj.find(filter => filter.type == "status")
      ?.filter_value.value
    const filterLocation = filterObj.find(filter => filter.type == "location")
      ?.filter_value.value
    // const filterTenancy = filterObj.find(filter => filter.type == "tenure")
    //   ?.filter_value.value

    let filteredProplist: PropertyCardModel[] = dataList

    if (filterType != "All") {
      filteredProplist = filteredProplist.filter(property => {
        return property.type == filterType
      })
    }

    if (filterStatus != "All") {
      filteredProplist = filteredProplist.filter(property => {
        return property.status == filterStatus
      })
    }

    if (filterLocation != "All") {
      filteredProplist = filteredProplist.filter(property => {
        return property.state == filterLocation
      })
    }

    // if (filterTenancy != "All") {
    //   filteredProplist = filteredProplist.filter(property => {
    //     return property.tenancy_type == filterTenancy
    //   })
    // }

    setProperties(filteredProplist)
  }, [filterObj])

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={"The"}
        firstColor={"primary"}
        secondWord={"Property"}
        secondColor={"warning"}
      />
      {showFilter ? (
        <FilterComp
          btnImgSrc={FilterIcon.src}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          colLength={4}
        />
      ) : (
        <></>
      )}

      <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4">
        {properties && properties.length > 0 ? (
          properties.map(
            (data, idx) =>
              ((idx > 7 && !hidden) || idx < 8) && (
                <Col key={idx}>
                  <PropertyCard
                    id={data.id}
                    imageUrl={data.imageUrl}
                    imageAlt={data.imageAlt}
                    name={data.name}
                    location={data.location}
                    developer={data.developer}
                    tags={data.tags}
                    onCardClick={handleCardClick}
                  />
                </Col>
              )
          )
        ) : (
          <Col xs="12" className="w-100">
            <NoDataComponent msg="No Properties to show" />
          </Col>
        )}
      </Row>
      {properties.length > 8 && hidden && (
        <Row className="mt-4">
          <Col>
            <Button
              variant="primary"
              className="text-light py-2 px-4"
              onClick={() => setHidden(!hidden)}>
              Load more
            </Button>
          </Col>
        </Row>
      )}
    </div>
  )
}
