import { MutableRefObject, useEffect, useState } from "react"
import MapBackground from "@assets/coverage-map.png"

export default function InvestmentCoverageAreaLogic(
  mapRef: MutableRefObject<HTMLCanvasElement>,
  pointerRef: MutableRefObject<HTMLCanvasElement>,
  coverageData
) {
  const [toolTipIdState, setToolTipIdState] = useState(0)
  const [showToolTipState, setShowToolTipState] = useState(false)
  const coverages = coverageData.map(obj => {
    let propertyArr = obj.property.split(",")
    return {
      city: obj.city,
      property: propertyArr,
    }
  })

  const toolTipData = {
    "1": {
      city: coverages[0]?.city,
      property: coverages[0]?.property,
    },
    "2": {
      city: coverages[1]?.city,
      property: coverages[1]?.property,
    },
    "3": {
      city: coverages[2]?.city,
      property: coverages[2]?.property,
    },
    "4": {
      city: coverages[3]?.city,
      property: coverages[3]?.property,
    },
    "5": {
      city: coverages[4]?.city,
      property: coverages[4]?.property,
    },
    "6": {
      city: coverages[5]?.city,
      property: coverages[5]?.property,
    },
    "7": {
      city: coverages[6]?.city,
      property: coverages[6]?.property,
    },
    "8": {
      city: coverages[7]?.city,
      property: coverages[7]?.property,
    },
    "9": {
      city: coverages[8]?.city,
      property: coverages[8]?.property,
    },
    "10": {
      city: coverages[9]?.city,
      property: coverages[9]?.property,
    },
    "11": {
      city: coverages[10]?.city,
      property: coverages[10]?.property,
    },
  }

  //Base Calculation
  const BASE_WIDTH = 700
  const BASE_HEIGHT = 360
  useEffect(() => {
    const dotsRadius = 8
    const mapCanvasRef = mapRef.current
    mapCanvasRef.width = BASE_WIDTH
    mapCanvasRef.height = BASE_HEIGHT
    const rWidth = mapCanvasRef.width / BASE_WIDTH
    const rHeight = mapCanvasRef.height / BASE_HEIGHT
    const mapCanvasContext = mapCanvasRef.getContext("2d")
    const image = new Image()

    let pointedDots = undefined

    //Load Image
    image.onload = () => {
      mapCanvasContext.drawImage(image, 0, 0, BASE_WIDTH, BASE_HEIGHT)
    }

    image.src = MapBackground.src

    const pointerCanvasRef = pointerRef.current
    pointerCanvasRef.width = BASE_WIDTH
    pointerCanvasRef.height = BASE_HEIGHT
    const pointerCanvasContext = pointerCanvasRef.getContext("2d")

    //Draw circle
    function point(
      id: number,
      x: number,
      y: number,
      canvas: CanvasRenderingContext2D,
      mouseX: number,
      mouseY: number
    ) {
      let isPointing = false
      //Check if pointing to circle
      if (
        x <= mouseX &&
        mouseX <= x + dotsRadius * 2 &&
        y <= mouseY &&
        mouseY <= y + dotsRadius * 2
      ) {
        pointedDots = {
          id: id,
          x: x,
          y: y,
        }
        isPointing = true
        // console.log(x, y, mouseX, mouseY, dotsRadius)
      }
      // if (canvas.isPointInPath(mouseX, mouseY)) {
      //   console.log(x, y, mouseX, mouseY)
      // }
      canvas.globalAlpha = isPointing ? 1 : 0.4
      canvas.fillStyle = "#000000"
      canvas.beginPath()
      canvas.arc(x * rWidth, y * rHeight, dotsRadius, 0, Math.PI * 2, true)
      canvas.closePath()
      canvas.fill()
    }

    //Dots Setting
    const dots = [
      // Langkawi
      {
        id: 1,
        x: 9,
        y: 19,
      },
      // Kelantan
      {
        id: 2,
        x: 135,
        y: 33,
      },
      // Penang
      {
        id: 3,
        x: 33,
        y: 70,
      },
      // Ipoh
      {
        id: 4,
        x: 78,
        y: 110,
      },
      // Genting
      {
        id: 5,
        x: 125,
        y: 165,
      },
      // Kuala Lumpur
      {
        id: 6,
        x: 110,
        y: 180,
      },
      // Semenyih
      {
        id: 7,
        x: 114,
        y: 193,
      },
      // Melaka
      {
        id: 8,
        x: 140,
        y: 230,
      },
      // Desaru
      {
        id: 9,
        x: 217,
        y: 225,
      },
      // Johor Bahru
      {
        id: 10,
        x: 212,
        y: 272,
      },
      // Sabah
      {
        id: 11,
        x: 538,
        y: 83,
      },
    ]
    function drawAllCircle(mouseX, mouseY) {
      pointedDots = undefined
      //Clear
      pointerCanvasContext.clearRect(
        0,
        0,
        pointerCanvasRef.width,
        pointerCanvasRef.height
      )

      //Redraw dots
      dots.forEach(dot => {
        // console.log(dot.x, dot.y, mouseX, mouseY)
        point(
          dot.id,
          dot.x,
          dot.y,
          pointerCanvasContext,
          mouseX + dotsRadius,
          mouseY + dotsRadius
        )
      })
    }

    drawAllCircle(0, 0)

    //Track mouse movement
    pointerCanvasRef.addEventListener("mousemove", e => {
      // console.log("Mouse pointer", e.offsetX, e.offsetY)
      drawAllCircle(e.offsetX, e.offsetY)

      setToolTipIdState(0)
      setShowToolTipState(false)
    })

    //Target Path
    // X - 350
    // Y - 156
    const targetPath = {
      x: 350,
      y: 156,
    }
    pointerCanvasRef.addEventListener("click", e => {
      // console.log(e.offsetX, e.offsetY)
      if (pointedDots) {
        // set line stroke and line width
        pointerCanvasContext.strokeStyle = "#FFC20E"
        pointerCanvasContext.lineWidth = 2
        pointerCanvasContext.globalAlpha = 1
        // Start Drawing
        pointerCanvasContext.beginPath()
        // Decide Starting Point First
        pointerCanvasContext.moveTo(
          pointedDots.x * rWidth,
          (pointedDots.y - dotsRadius) * rHeight
        )
        pointerCanvasContext.lineTo(
          pointedDots.x * rWidth,
          targetPath.y * rHeight
        )
        pointerCanvasContext.lineTo(
          targetPath.x * rWidth,
          targetPath.y * rHeight
        )
        pointerCanvasContext.stroke()

        setToolTipIdState(pointedDots.id)
        setShowToolTipState(true)
      }
    })
  }, [])

  return {
    toolTipData,
    toolTipIdState,
    showToolTipState,
  }
}
