import styled from "@emotion/styled"
import { Badge, Card } from "react-bootstrap"
import { PropertyCardModel } from "./PropertyCardModel"
import NoImg from "@assets/no-image.png"
import Image from "next/image"

const StyledSmall = styled.small(
  {
    display: "block",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const StyledCard = styled(Card)(
  {
    borderRadius: "0",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    position: "relative",
    transition: ".5s",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.125)",
      zIndex: 1,
    },
  },
  ({ theme }) => ({
    border: `1px solid ${theme.colors.greybright}`,
  })
)

export default function PropertyCard({
  id,
  imageUrl,
  imageAlt,
  name,
  location,
  developer,
  tags,
  onCardClick,
}: PropertyCardModel) {
  return (
    <StyledCard
      style={{
        border: "1px solid var(--grey-bright)",
        borderRadius: "0",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        height: "100%",
      }}
      onClick={() => onCardClick(id, name)}>
      <Image
        src={imageUrl ? imageUrl : NoImg.src}
        alt={imageAlt}
        layout="responsive"
        width={15}
        height={11}
        objectFit="cover"
        style={{
          borderRadius: "0",
        }}
      />
      <Card.Body className="text-left">
        <Card.Title as="h6">{name}</Card.Title>
        <StyledSmall className="text-secondary d-block">{location}</StyledSmall>
        <StyledSmall className="text-secondary mb-3">{developer}</StyledSmall>
        {tags.map((tag, idx) => (
          <Badge
            key={idx}
            bg="light"
            className="me-2 mb-2 text-dark border border-primary fw-normal">
            {tag}
          </Badge>
        ))}
      </Card.Body>
    </StyledCard>
  )
}
