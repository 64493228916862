import { Card } from "react-bootstrap"
import { HasCardItemModel } from "./HasCardItem.model"
import NoImg from "@assets/no-image.png"
import Image from "next/image"

export default function HasCardItem({
  id,
  imageUrl,
  imageAlt,
  title,
  description,
  altBg,
  urlSlug,
  isImgSquare,
  onClick,
  projectDetails,
}: HasCardItemModel) {
  const convertVpDate = vpDate => {
    const date = new Date(vpDate)
    const yyyy = date.getFullYear()
    let mm = String(date.getMonth() + 1) // Months start at 0!
    let dd = String(date.getDate())

    if (date.getDate() < 10) dd = "0" + dd
    if (date.getMonth() + 1 < 10) mm = "0" + mm

    return dd + "/" + mm + "/" + yyyy
  }

  return (
    <Card
      style={{
        border: "0",
        borderRadius: "0",
        cursor: "pointer",
      }}
      onClick={() => onClick(id, urlSlug)}>
      {isImgSquare ? (
        <Image
          className="w-100"
          src={imageUrl ? imageUrl : NoImg.src}
          layout="responsive"
          width={1}
          height={1}
          objectFit="cover"
          alt={imageAlt}
          style={{
            borderRadius: "0",
          }}
        />
      ) : (
        <Image
          className="w-100"
          src={imageUrl ? imageUrl : NoImg.src}
          layout="responsive"
          width={15}
          height={11}
          objectFit="cover"
          alt={imageAlt}
          style={{
            borderRadius: "0",
          }}
        />
      )}

      <Card.Body
        className="text-left"
        style={{
          background: altBg && "var(--grey-bright)",
          paddingLeft: "0",
          paddingRight: "0",
        }}>
        <Card.Title as="h6" className="limit-1-line">{title}</Card.Title>
        {description && <small className="text-secondary limit-2-lines">{description}</small>}
        {projectDetails && (
          <>
            <div>
              <small>
                VP Date :{" "}
                {projectDetails.vpDate
                  ? convertVpDate(projectDetails.vpDate)
                  : "-"}
              </small>
            </div>
            <div>
              <small>
                Market ROI :{" "}
                {projectDetails.marketROI ? projectDetails.marketROI : "0"}
              </small>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
