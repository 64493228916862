import { HasOtaPartnersModel } from "@components/HasOtaPartners/HasOtaPartners.model"
import { HeroBannerModel } from "@components/HeroBanner/HeroBanner.model"
import { HomeLoanInterestRatesModel } from "@components/HomeLoanInterestRates/HomeLoanInterestRates.model"
import { LoanStrategyAdvisorModel } from "@components/LoanStrategyAdvisor/LoanStrategyAdvisor.model"
import { OurStoryModel } from "@components/OurStory/OurStory.model"
import { VisionMissionModel } from "@components/VisionMission/VisionMission.model"
import HTTPServices from "./HttpServices"
import { MetaModel } from "./SharedServices"
import { LoanModel } from "@shared/models/Loan.model"
import { BASE_PATH } from "@shared/services/HttpServices"

export default function FinancingServices() {
  const getPageProperties = async () => {
    const res = await new HTTPServices("strapi").get("/financing", {
      populate: {
        hero_banner: {
          populate: ["image"],
        },
        home_loan_interest_rates: {
          populate: "*",
        },
        loan_data: {
          populate: "*",
        },
      },
    })

    if (res.data.data) {
      const data = res.data.data

      //Simplify
      //Meta
      const meta: MetaModel = data.attributes

      //Hero Banner
      const heroBannerAttributes = data.attributes.hero_banner.data.attributes
      const heroBanner: HeroBannerModel = {
        imageUrl: heroBannerAttributes.image.data.attributes.url,
        imageAlt: heroBannerAttributes.image.data.attributes.alternativeText,
        title: heroBannerAttributes.title,
        description: heroBannerAttributes.subtitle,
      }

      //Home Loan Interest Rates
      const homeLoan: HomeLoanInterestRatesModel = {
        sectionTitle: {
          firstWord: data.attributes.home_loan_title_first_word,
          secondWord: data.attributes.home_loan_title_second_word,
        },
        interest_rates: [],
      }
      data.attributes.home_loan_interest_rates.data.forEach(item => {
        homeLoan.interest_rates.push({
          id: item.id,
          bank_lender_name: item.attributes.bank_lender_name,
          interest_rate: item.attributes.interest_rate,
        })
      })

      //Loan Strategy
      const loanStrategy: LoanStrategyAdvisorModel = {
        sectionTitle: {
          firstWord: data.attributes.loan_strategy_title_first_word,
          secondWord: data.attributes.loan_strategy_title_second_word,
        },
        caption_title: data.attributes.loan_strategy_caption_title,
        caption_subtitle: data.attributes.loan_strategy_caption_subtitle,
        cta_text: data.attributes.cta_text,
        cta_link: data.attributes.cta_link,
      }

      // loan datas
      const loanDatas: LoanModel[] = []
      data.attributes.loan_data.data.forEach(item => {
        loanDatas.push({
          id: item.id,
          bank_and_lender: item.attributes.bank_and_lender,
          img: `${BASE_PATH}${item.attributes.img.data.attributes.url}`,
          monthly: item.attributes.monthly,
          interest_rate: item.attributes.interest_rate,
          max_tenure: item.attributes.max_tenure,
          max_margin: item.attributes.max_margin,
          lock_in_period: item.attributes.lock_in_period,
          full_flexi_loan: item.attributes.full_flexi_loan ? "Yes" : "No",
          details: item.attributes.details,
        })
      })

      return {
        meta,
        heroBanner,
        homeLoan,
        loanStrategy,
        loanDatas,
      }
    } else {
      return null
    }
  }

  const submitLoanApplication = async req => {
    const res = await new HTTPServices("strapi").post("/loan-applications", {
      data: req,
    })

    if (res.data) {
      return true
    } else {
      return false
    }
  }

  return {
    getPageProperties,
    submitLoanApplication,
  }
}
