import { Card, Image } from "react-bootstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import PadLock from "@assets/lock.png"

const EmptyChartDiv = styled.div(
  ({ theme }) => ({
    backgroundColor: theme.colors.greybright,
  }),
  {
    borderRadius: "1.25rem",
    flexGrow: "1",
    height: "0.25rem",
    alignItems: "center",
    margin: "0 0.375rem",
  }
)

const ValueDiv = styled.div(
  ({ theme }) => ({
    backgroundColor: theme.colors.primary,
  }),
  {
    borderRadius: "1.25rem",
    flexGrow: "1",
    height: "0.25rem",
    alignItems: "center",
  }
)

export interface MarketGradeModel {
  name: string
  value: number
  active: boolean
}

export default function MarketGrade({ dataList }) {
  const theme = useTheme()
  return (
    <Card style={{ padding: "0.75rem", height: "100%" }}>
      <h5 style={{ fontWeight: "500" }}>Market Grade</h5>
      <div className="d-flex flex-column h-100">
        {dataList &&
          dataList.map((data: MarketGradeModel) => (
            <div
              key={data.name}
              className="d-flex w-100 align-items-center flex-grow-1">
              <div style={{ color: theme.colors.secondary, width: "5.25rem" }}>
                <span className="small">{data.name}</span>
              </div>
              <EmptyChartDiv>
                {data.active && (
                  <ValueDiv style={{ width: data.value + "%" }} />
                )}
              </EmptyChartDiv>
              <div style={{ color: theme.colors.secondary, width: "1.5rem" }}>
                {data.active ? (
                  <span className="small">{data.value}</span>
                ) : (
                  <Image src={PadLock.src} alt="" style={{ height: "1rem" }} />
                )}
              </div>
            </div>
          ))}
      </div>
    </Card>
  )
}
