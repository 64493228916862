import { SectionTitleModel } from "./SectionTitle.model"
import styled from "@emotion/styled"

const StyledDiv = styled.div({
  "&::after": {
    content: '""',
    display: "block",
    width: "5rem",
    height: ".25rem",
    background: "var(--bs-warning)",
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },
})

export default function SectionTitle({
  firstColor = "primary",
  firstWord,
  secondColor = "warning",
  secondWord,
}: SectionTitleModel) {
  return (
    <StyledDiv className="section-title pb-3 mb-4 position-relative text-center">
      <h2>
        {firstWord && (
          <span className={`me-2 text-${firstColor}`}>{firstWord}</span>
        )}
        {secondWord && (
          <span className={`text-${secondColor}`}>{secondWord}</span>
        )}
      </h2>
    </StyledDiv>
  )
}
