export default function FooterCopyright() {
  return (
    <div
      style={{
        background: "#00B7CE",
        minHeight: "25px",
      }}>
      <div className="container">
        <p
          className="mb-0"
          style={{
            fontSize: ".75rem",
          }}>
          Copyright { new Date().getFullYear() } HostAStay. All Rights Reserved
        </p>
      </div>
    </div>
  )
}
