import { Container, Row, Col } from "react-bootstrap"

import PropertyOverview from "./PropertyOverview"
import PropertyImage from "./PropertyImage"
import PropertySpecific from "./PropertySpecific"
import PropertyTable from "./PropertyTable"
import PropertyFacilityAmenity from "./PropertyFacilityAmenity"
import PropertyLocation from "./PropertyLocation"
import PropertyDeveloper from "./PropertyDeveloper"
import MarketAnalysis from "./MarketAnalysis"
import PartnerSlider from "./PartnerSlider"
import MightLike from "./MightLike"
import { useRouter } from "next/router"
import GetPropertyDetail from "./PropertyDetail.logic"
import { OnPropertyClick } from "lib/InvestmentProperty/utils"

export default function PropertyDetail({ propertyList }) {
  const router = useRouter()
  const { uid } = router.query
  const { propertyDetail } = GetPropertyDetail(uid as string)

  return (
    <div>
      <Container className="py-5">
        <PropertyOverview
          name={propertyDetail.name}
          status={propertyDetail.status}
          address_1={propertyDetail.address_1}
          address_2={propertyDetail.address_2}
          post_code={propertyDetail.post_code}
          state={propertyDetail.state}
          description={propertyDetail.description}
          tags={propertyDetail.tags}
          img={propertyDetail.images}
        />
      </Container>
      <Container fluid className="g-0 pb-4">
        <PropertyImage images={propertyDetail.images} />
      </Container>
      <Container>
        <Row>
          <Col md={8}>
            <PropertySpecific
              price_range={propertyDetail.price_range}
              management_office_location={
                propertyDetail.management_office_location
              }
              built_up_size={propertyDetail.built_up_size}
              listed_at={propertyDetail.listed_at}
              type={propertyDetail.type}
              built_up_price={propertyDetail.built_up_price}
              completion_year={propertyDetail.completion_year}
            />
            <hr />
            <div className="pb-3">
              <PropertyTable
                unit_types={propertyDetail.unit_types}
                display_suite_link={propertyDetail.display_suite_link}
              />
            </div>
            <hr />
            <div className="pb-3">
              <PropertyFacilityAmenity
                additional_informations={propertyDetail.additional_informations}
              />
            </div>
            <hr />
            <div className="pb-3">
              <PropertyLocation
                lat={propertyDetail.lat}
                lng={propertyDetail.lng}
              />
            </div>
          </Col>
          <Col md={4}>
            <PropertyDeveloper developer={propertyDetail.developer} />
          </Col>
        </Row>
        <hr />
        <div className="pb-3">
          <MarketAnalysis
            market_grade={propertyDetail.market_grade}
            average_daily_rate={propertyDetail.average_daily_rate}
            occupancy_rate={propertyDetail.occupancy_rate}
            revenue_rate={propertyDetail.revenue_rate}
          />
        </div>
        <hr />
        <div className="pb-3">
          <PartnerSlider
            title="Host Partners"
            dataList={propertyDetail.hosts}
            prefix="hpr"
          />
        </div>
        <hr />
        <div className="pb-3">
          <PartnerSlider
            title="Reno Partners"
            dataList={propertyDetail.renovation_partners}
            prefix="rpr"
          />
        </div>
        <hr />
        <div className="pb-3">
          <PartnerSlider
            title="Agent"
            dataList={propertyDetail.agents}
            prefix="apr"
          />
        </div>
      </Container>
      <Container fluid className="g-0">
        <MightLike
          propertyList={propertyList}
          handlePropertyClick={OnPropertyClick}
        />
      </Container>
    </div>
  )
}
