import dynamic from "next/dynamic"
import { Card, Image } from "react-bootstrap"
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })
import ArrowRight from "@assets/arrow-right.svg"

export interface ChartContentModel {
  firstVal: number
  secondVal: number
  firstLabel: string
  secondLabel: string
  value: string
  color: string
}

export default function RateCard({ title, content }) {
  let chartContent: ChartContentModel = content
  const chartConfig = {
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 7,
      },
      grid: {
        show: false,
      },
      stroke: {
        curve: "smooth" as "smooth",
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
      },
      xaxis: {
        categories: [chartContent.firstLabel, chartContent.secondLabel],
      },
      colors: [chartContent.color],
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        name: "RM",
        data: [chartContent.firstVal, chartContent.secondVal],
      },
    ],
  }

  return (
    <Card style={{ padding: "0.75rem", height: "100%" }}>
      <h5 style={{ fontWeight: "500" }}>{title}</h5>
      <div className="d-flex justify-content-center">
        <Chart
          type="line"
          options={chartConfig.options}
          series={chartConfig.series}
          height="100px"
          width="80%"></Chart>
      </div>
      <div className="w-100 text-center">
        <h4>{chartContent.value}</h4>
      </div>
      {/* <a href="#" style={{ textDecoration: "none" }}>
        View Rates <Image src={ArrowRight.src} alt="" />
      </a> */}
    </Card>
  )
}
