import { Col, Row } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"
import { HasCardItem } from "components/HasCardItem"
import SearchIcon from "@assets/search-icon.png"
import { useEffect, useState } from "react"
import { FilterComp } from "components/FilterComp"
import { FilterComponentModel } from "@components/FilterComp/FilterComp.model"
import { HASPartnerMasterlistModel } from "./HASPartnerMasterlist.model"
import { BASE_PATH } from "@shared/services/HttpServices"
import { FilterModel } from "@shared/models/Filter.model"
import { NoDataComponent } from "components/NoDataComponent"

const allFilter: FilterModel = {
  name: "All",
  value: "All",
}

export default function HASPartnerMasterlist({
  dataSource,
  typeFilter,
  statusFilter,
  handleCardClick,
}: {
  dataSource: HASPartnerMasterlistModel
  typeFilter: FilterModel[]
  statusFilter: FilterModel[]
  handleCardClick: Function
}) {
  const initialState: FilterComponentModel[] = [
    {
      filter_name: "Partner Type",
      type: "type",
      filter_value: {
        ...allFilter,
      },
      filter_selections: [
        {
          ...allFilter,
        },
        ...typeFilter,
      ],
    },
    {
      filter_name: "Partner Status",
      type: "status",
      filter_value: {
        name: "All",
        value: "All",
      },
      filter_selections: [
        {
          ...allFilter,
        },
        ...statusFilter,
      ],
    },
  ]

  const initialHasPartners = dataSource.has_partners

  const [filterObj, setFilterObj] = useState(initialState)
  const [partners, setPartners] = useState(initialHasPartners)

  useEffect(() => {
    const filterType = filterObj.find(filter => filter.type == "type")
      .filter_value.value

    const filterStatus = filterObj.find(filter => filter.type == "status")
      .filter_value.value
    setPartners(
      initialHasPartners.filter(partner => {
        if (filterType != "All" && filterStatus != "All") {
          return (
            partner.types.includes(filterType) && partner.status == filterStatus
          )
        } else if (filterStatus != "All") {
          return partner.status == filterStatus
        } else if (filterType != "All") {
          return partner.types.includes(filterType)
        }

        return partner
      })
    )
  }, [filterObj])

  return (
    <div className="my-5 pb-5">
      <div className="d-flex justify-content-center">
        <SectionTitle
          firstWord={dataSource.sectionTitle.firstWord}
          firstColor="primary"
          secondWord={dataSource.sectionTitle.secondWord}
          secondColor="warning"
        />
      </div>
      <FilterComp
        btnImgSrc={SearchIcon.src}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        colLength={6}
      />
      <Row className="row-cols-2 row-cols-sm-3 row-cols-lg-5">
        {partners && partners.length > 0 ? (
          partners.map(data => (
            <Col key={data.id}>
              <HasCardItem
                id={data.id + ""}
                imageUrl={`${BASE_PATH}${data.imageUrl}`}
                imageAlt={data.imageAlt}
                title={data.name}
                urlSlug={data.url_slug}
                description=""
                altBg={false}
                isImgSquare={true}
                onClick={handleCardClick}
              />
            </Col>
          ))
        ) : (
          <Col xs={12} className="w-100">
            <NoDataComponent msg="No Has Partners to show" />
          </Col>
        )}
      </Row>
      {partners.length > 10 ? (
        <div className="d-flex justify-content-center py-5">
          <button className="text-light py-2 px-4 btn btn-primary">
            Load More {">"}
          </button>
        </div>
      ) : null}
    </div>
  )
}
