import { Row } from "react-bootstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { CSSProperties, ReactNode } from "react"

const ContainerDiv = styled.div(
  {
    padding: "2rem",
    borderRadius: "1.25rem",
    width: "100%",
  },
  ({ theme }) => ({
    backgroundColor: theme.colors.greybright,
  })
)

export default function CustomGreyContainer({
  className,
  style,
  children,
}: {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}) {
  const theme = useTheme()
  return (
    <ContainerDiv className={className} style={style}>
      {children}
    </ContainerDiv>
  )
}
