import { useTheme } from "@emotion/react"

export default function NoDataComponent({
  msg,
  inlineCss,
}: {
  msg: string
  inlineCss?: any
}) {
  const theme = useTheme()
  return (
    <p
      style={{
        color: theme.colors.secondary,
        textAlign: "center",
        ...inlineCss,
      }}>
      {msg}
    </p>
  )
}
