import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { LoanModel } from "@shared/models/Loan.model"
import { LoanFinancialCardItem } from "components/LoanFinancialCardItem"
import { SectionTitle } from "components/SectionTitle"
import { CustomGreyContainer, CustomSection } from "layouts"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { NoDataComponent } from "components/NoDataComponent"
import { LoanFinancialModel } from "./LoanFinancial.model"

const PrimaryHeader = styled.h4(
  {
    fontWeight: "700",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const SecondaryHeader = styled.h5(
  {
    fontWeight: "400",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const OverflowHorizontalDiv = styled.div({
  overflowX: "scroll",
  borderRadius: "0.625rem",
  width: "100%",
})

export default function LoanFinancial({
  loanDatas,
  setLoan,
}: LoanFinancialModel) {
  const theme: any = useTheme()
  const [selectedList, setSelected] = useState<LoanModel[]>([])

  function modifyList(loanData: LoanModel, isAdd: boolean) {
    let currList: LoanModel[] = selectedList
    if (isAdd) {
      currList.push(loanData)
    } else {
      currList = currList.filter(obj => {
        if (obj.id != loanData.id) {
          return obj
        }
      })
    }
    setSelected(currList)
  }

  return (
    <div className="text-center w-100 py-5">
      <SectionTitle
        firstWord="Loan"
        firstColor="primary"
        secondWord="Financial"
        secondColor="warning"
      />
      <div className="overflow-hidden">
        <OverflowHorizontalDiv>
          <div
            style={{
              width: "1100px",
            }}>
            <div
              style={{
                marginBottom: "0.375rem",
              }}>
              <CustomGreyContainer>
                <Row>
                  <Col xs="10">
                    <Row>
                      <Col xs="2">
                        <PrimaryHeader>Bank And Lender</PrimaryHeader>
                      </Col>
                      <Col xs="10">
                        <Row>
                          <Col xs="2">
                            <SecondaryHeader>Monthly</SecondaryHeader>
                          </Col>
                          <Col xs="2">
                            <SecondaryHeader>Interest Rate</SecondaryHeader>
                          </Col>
                          <Col xs="2">
                            <SecondaryHeader>Max Tenure</SecondaryHeader>
                          </Col>
                          <Col xs="2">
                            <SecondaryHeader>Max Margin</SecondaryHeader>
                          </Col>
                          <Col xs="2">
                            <SecondaryHeader>Lock In Period</SecondaryHeader>
                          </Col>
                          <Col xs="2">
                            <SecondaryHeader>Full Flexi Loan?</SecondaryHeader>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="2">
                    <button
                      className="btn"
                      style={{
                        backgroundColor: `${theme.colors.primary}`,
                        color: `${theme.colors.light}`,
                        borderRadius: "30px",
                        padding: "0.625rem 1.75rem",
                      }}
                      onClick={() => {
                        if (selectedList.length > 0) {
                          setLoan(selectedList)
                        }
                      }}>
                      Proceed
                    </button>
                  </Col>
                </Row>
              </CustomGreyContainer>
            </div>

            {loanDatas && loanDatas.length > 0 ? (
              loanDatas.map((elm, index, arr) => (
                <LoanFinancialCardItem
                  key={elm.id}
                  loanData={elm}
                  modifyList={modifyList}></LoanFinancialCardItem>
              ))
            ) : (
              <CustomGreyContainer>
                <NoDataComponent msg="No Loans to show" />
              </CustomGreyContainer>
            )}
          </div>
        </OverflowHorizontalDiv>
      </div>
    </div>
  )
}
