import { useState } from "react"

export default function PropertyLocation({
  lat,
  lng,
}: {
  lat: string
  lng: string
}) {
  let latitude = "3.118553"
  let longitude = "101.691412"
  if (lat && lng) {
    latitude = lat
    longitude = lng
  }
  const [mapUrl, setUrl] = useState(
    `https://maps.google.com/maps?q=${latitude},${longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`
  )

  return (
    <div className="project-map-location">
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        Location
      </h4>
      <div className="project-map-location-content">
        {mapUrl && (
          <iframe
            src={mapUrl}
            width="100%"
            height="450"
            style={{ border: "0" }}
            loading="lazy"></iframe>
        )}
      </div>
    </div>
  )
}
