import { useRef } from "react"
// import { Image } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"

import InvestmentCoverageAreaLogic from "./InvestmentConverageArea.logic"

export default function InvestmentCoverageArea({ coverageData }) {
  const mapRef = useRef<HTMLCanvasElement>()
  const pointerRef = useRef<HTMLCanvasElement>()
  const { toolTipData, toolTipIdState, showToolTipState } =
    InvestmentCoverageAreaLogic(mapRef, pointerRef, coverageData)

  return (
    <div className="position-relative text-center">
      <div className="d-inline-block position-relative mt-5">
        {/* Map Canvas */}
        <canvas
          ref={mapRef}
          width="1000"
          height="489"
          className="w-100"
          style={{
            maxWidth: "60rem",
          }}
        />
        {/* Pointer Canvas */}
        <canvas
          ref={pointerRef}
          width="1000"
          height="489"
          className="position-absolute w-100"
          style={{
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
        {/* Tooltip */}
        <div
          className="position-absolute d-flex flex-column align-items-center"
          style={{
            top: "35%",
            left: "45%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            opacity: showToolTipState ? 1 : 0,
          }}>
          <div
            className="bg-grey-bright mb-1 px-2 py-1"
            style={{
              minWidth: "11rem",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
              borderRadius: ".25rem",
            }}>
            <p className="text-primary mb-0 text-left">
              {toolTipData[toolTipIdState]?.city}
            </p>
          </div>
          <div
            className="bg-grey-bright d-flex flex-column text-left px-2"
            style={{
              minWidth: "10rem",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
              borderRadius: ".25rem",
            }}>
            {toolTipData[toolTipIdState]?.property.map((prop, index) => (
              <div key={index}>
                <small className="text-primary">{prop}</small>
                {toolTipData[toolTipIdState]?.property.length - 1 != index ? (
                  <hr className="bg-primary my-0" />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}>
        <SectionTitle
          firstWord="Investment"
          firstColor="light"
          secondWord="Coverage Area"
          secondColor="warning"
        />
      </div>
    </div>
  )
}
