import { Modal } from "react-bootstrap"
import ReactPlayer from "react-player/lazy"

export default function VideoPlayer({
  show,
  onHide,
  videoUrl,
}: {
  show: boolean
  onHide?: Function
  videoUrl?: string
}) {
  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ReactPlayer url={videoUrl} controls={true} width="100%" />
    </Modal>
  )
}
