import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { CustomGreyContainer } from "layouts"
import Image from "next/image"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"

const GreySpan = styled.span(
  {
    fontSize: "1.25rem",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const SecondaryHeaderSpan = styled.span(
  {
    fontSize: "1rem",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

export default function LoanFinancialCardSelected({ loanData }) {
  const theme = useTheme()

  return (
    <div style={{ marginBottom: "0.375rem", width: "100%" }}>
      <CustomGreyContainer>
        <Row>
          <Col xs="3">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <GreySpan
                style={{
                  fontWeight: "600",
                }}>
                {loanData.bank_and_lender}
              </GreySpan>
            </div>
          </Col>
          <Col xs="9">
            <Row>
              <Col xs="2">
                <SecondaryHeaderSpan>Monthly</SecondaryHeaderSpan>
              </Col>
              <Col xs="2">
                <SecondaryHeaderSpan>Interest Rate</SecondaryHeaderSpan>
              </Col>
              <Col xs="2">
                <SecondaryHeaderSpan>Max Tenure</SecondaryHeaderSpan>
              </Col>
              <Col xs="2">
                <SecondaryHeaderSpan>Max Margin</SecondaryHeaderSpan>
              </Col>
              <Col xs="2">
                <SecondaryHeaderSpan>Lock In Period</SecondaryHeaderSpan>
              </Col>
              <Col xs="2">
                <SecondaryHeaderSpan>Full Flexi Loan?</SecondaryHeaderSpan>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: "1px solid #00B7CE",
            display: "flex",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
          }}>
          <Col xs="3">
            <div style={{ maxWidth: "150px" }}>
              <Image
                src={loanData.img}
                layout="responsive"
                width={3}
                height={1}
                objectFit="cover"
              />
            </div>
          </Col>
          <Col xs="9">
            <Row>
              <Col xs="2">
                <GreySpan>{loanData.monthly}</GreySpan>
              </Col>
              <Col xs="2">
                <GreySpan>{loanData.interest_rate} %</GreySpan>
              </Col>
              <Col xs="2">
                <GreySpan>{loanData.max_tenure} Years</GreySpan>
              </Col>
              <Col xs="2">
                <GreySpan>{loanData.max_margin} %</GreySpan>
              </Col>
              <Col xs="2">
                <GreySpan>{loanData.lock_in_period} Years</GreySpan>
              </Col>
              <Col xs="2">
                <GreySpan>{loanData.full_flexi_loan}</GreySpan>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomGreyContainer>
    </div>
  )
}
