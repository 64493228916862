import { NextArrow, PrevArrow } from "components/CustomSlick"
import { Image } from "react-bootstrap"
import Slider from "react-slick"
import SampleImg from "@assets/has-partners-img-01.jpg"
import { NoDataComponent } from "components/NoDataComponent"

export default function PartnerSlider({ title, dataList, prefix }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    mobileFirst: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          prevArrow: <PrevArrow inlineCss={{ left: "1rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "1rem" }} />,
        },
      },
    ],
  }

  return (
    <div>
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        {title}
      </h4>
      {dataList && dataList.length > 0 ? (
        <Slider {...settings}>
          {dataList.map(data => (
            <div className="p-2" key={`${prefix}${data.id}`}>
              <Image
                src={data.avatar_url ? data.avatar_url : SampleImg.src}
                alt=""
                className="w-100"
              />
            </div>
          ))}
        </Slider>
      ) : (
        <NoDataComponent msg="No Datas to show" />
      )}
    </div>
  )
}
