import { SectionTitle } from "components/SectionTitle"
import parse from "html-react-parser"
import { OurStoryModel } from "./OurStory.model"

export default function OurStory({
  dataSource,
}: {
  dataSource: OurStoryModel
}) {
  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor={dataSource.sectionTitle.firstColor}
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor={dataSource.sectionTitle.secondColor}
      />
      {parse(dataSource.content)}
    </div>
  )
}
