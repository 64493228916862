// import { Image } from "react-bootstrap"
import styled from "@emotion/styled"
import Image from "next/image"

import { HeroBannerModel } from "./HeroBanner.model"

const StyledH3 = styled.h3`
  padding: 0.625rem 0.75rem;
  color: var(--bs-light);
  background: var(--bs-primary);
`

const BannerImageContainer = styled.div({
  width: "100vw",
  height: "28rem",
  position: "relative",
  "@media (min-width: 1366px)": {
    height: "40rem",
  },
  "@media (min-width: 1920px)": {
    height: "53rem",
  },
})

export default function HeroBanner({
  imageUrl,
  imageAlt,
  title,
  description,
}: HeroBannerModel) {
  return (
    <div className="position-relative">
      {imageUrl && (
        <BannerImageContainer>
          <Image
            src={imageUrl}
            alt={imageAlt}
            layout="fill"
            objectFit="cover"
            objectPosition="right"
            priority
          />
        </BannerImageContainer>
      )}
      <div
        className="position-absolute d-flex flex-column align-items-start"
        style={{
          top: "50%",
          left: "15%",
          transform: "translateY(-30%)",
        }}>
        {title && <StyledH3>{title}</StyledH3>}
        {description && (
          <StyledH3 style={{ fontWeight: 500 }}>{description}</StyledH3>
        )}
      </div>
    </div>
  )
}
