import { useState } from "react"
import { SectionTitle } from "components/SectionTitle"
import VideoPlayer from "@components/Videos/VideoPlayer"
import { RecommendedVideoModel } from "./RecommendedVideo.model"
import { BASE_PATH } from "@shared/services/HttpServices"
import { VideoCardItem } from "@components/VideoCardItem"

//Slick
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "components/CustomSlick"
import { NoDataComponent } from "components/NoDataComponent"

export default function RecommendedVideo({
  dataSource,
}: {
  dataSource: RecommendedVideoModel
}) {
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          prevArrow: <PrevArrow inlineCss={{ left: "1.5rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "1.5rem" }} />,
        },
      },
    ],
  }

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor={dataSource.sectionTitle.firstColor}
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor={dataSource.sectionTitle.secondColor}
      />
      <div
        style={{
          marginLeft: "-.5rem",
          marginRight: "-.5rem",
        }}>
        {dataSource.videos && dataSource.videos.length > 0 ? (
          <Slider {...settings}>
            {dataSource.videos.map(data => (
              <div className="p-2" key={data.id}>
                <VideoCardItem
                  imageUrl={`${BASE_PATH}${data.image_url}`}
                  imageAlt={data.image_alt}
                  title={data.video_title}
                  altBg={true}
                  isImgSquare={false}
                  onClick={() => {
                    setVideoUrl(data.video_url)
                    setModalShow(true)
                  }}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <NoDataComponent msg="No Videos to show" />
        )}
      </div>
      <VideoPlayer
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl={videoUrl}
      />
    </div>
  )
}
