import { Badge } from "react-bootstrap"
import styled from "@emotion/styled"
import Image from "next/image"

const StyledP = styled.p(
  {
    marginBottom: 0,
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const HeaderContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "@media (min-width: 768px)": {
    flexDirection: "row",
    alignItems: "center",
  },
})

export default function PropertyOverview({
  name,
  status,
  address_1,
  address_2,
  post_code,
  state,
  description,
  tags,
  img,
}) {
  return (
    <>
      <HeaderContainer className="mb-3">
        {img && img.length > 0 && (
          <div className="me-3 mb-3">
            <Image src={img[0].url} alt={""} width={50} height={50} />
          </div>
        )}
        <h2 className="me-2 mb-3 fw-normal">{name}</h2>
        <Badge bg="danger">
          <small>{status}</small>
        </Badge>
      </HeaderContainer>
      <div className="project-location">
        <StyledP>{`${address_1}, ${address_2}, ${post_code}, ${state}`}</StyledP>
      </div>
      <div className="project-overview mb-2">
        <StyledP>{description}</StyledP>
      </div>
      {tags &&
        tags.map((data, idx) => (
          <Badge
            key={idx}
            bg="light"
            className="me-2 text-dark border border-primary fw-normal">
            {data}
          </Badge>
        ))}
    </>
  )
}
