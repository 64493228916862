import { SectionTitle } from "@components/SectionTitle"
import styled from "@emotion/styled"
import { CustomGreyContainer } from "layouts"
import dynamic from "next/dynamic"
import { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

const CustomLabel = styled.label(
  {
    fontSize: "0.75rem",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  }),
  {
    whiteSpace: "nowrap",
  }
)

const CustomInput = styled.input(
  {
    width: "100%",
    borderRadius: "0.625rem",
    fontSize: "1.25rem",
    textAlign: "center",
  },
  ({ theme }) => ({
    borderColor: theme.colors.warning,
    color: theme.colors.secondary,
  })
)

const PrependDiv = styled.div(
  {
    height: "100%",
    borderRight: "none",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    borderTopLeftRadius: "0.625rem",
    borderBottomLeftRadius: "0.625rem",
    margin: "auto",
  },
  ({ theme }) => ({
    border: `1px solid ${theme.colors.warning}`,
    backgroundColor: theme.colors.light,
    color: theme.colors.warning,
  }),
  {
    "& h5": {
      borderRight: "1px solid",
      paddingRight: "0.625rem",
      marginBottom: "0",
      fontWeight: "400",
    },
  }
)

const AppendDiv = styled.div(
  {
    height: "100%",
    borderLeft: "none",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    borderTopRightRadius: "0.625rem",
    borderBottomRightRadius: "0.625rem",
    margin: "auto",
  },
  ({ theme }) => ({
    border: `1px solid ${theme.colors.warning}`,
    backgroundColor: theme.colors.light,
    color: theme.colors.warning,
  }),
  {
    "& h5": {
      borderLeft: "1px solid",
      paddingLeft: "0.625rem",
      marginBottom: "0",
      fontWeight: "400",
    },
  }
)

const CalculatorLabelSpan = styled.span(
  {
    fontSize: "0.75rem",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const CalculatorValueSpan = styled.span(
  {
    fontSize: "1.125rem",
    fontWeight: "500",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

export default function FinancingCalculator({
  firstTitle,
  secondTitle,
  currency,
}) {
  const [calcState, setCalcState] = useState({
    total: 0,
    interest: 0,
    dp: 0,
    duration: 1,
  })

  const handleOnFormChange = e => {
    setCalcState({ ...calcState, [e.target.name]: e.target.value })
  }

  const calculateMonthlyPayment = (rateInMonths, tenureInMonths, totalLoan) => {
    let rateTenure = Math.pow(1 + rateInMonths, tenureInMonths)
    let total = (rateInMonths * totalLoan * rateTenure) / (rateTenure - 1)

    if (isNaN(total)) {
      return 0.0
    } else {
      return total
    }
  }

  const calculateTotalInterest = (rateInMonths, tenureInMonths, totalLoan) => {
    let res =
      calculateMonthlyPayment(rateInMonths, tenureInMonths, totalLoan) *
        tenureInMonths -
      totalLoan

    if (isNaN(res) || res < 0) {
      return 0.0
    } else {
      return res
    }
  }

  const chartConfig = {
    options: {
      dataLabels: {
        style: {
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      colors: ["#00B6CD", "#607D8B"],
      legend: {
        fontSize: "12px",
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
        position: "right" as "right",
      },
      labels: [
        `Total Amount Financed (${currency} ${calcState.total})`,
        `Total Interest Payable (${currency} ${calculateTotalInterest(
          calcState.interest / 100 / 12,
          calcState.duration * 12,
          calcState.total - calcState.dp
        ).toFixed(2)})`,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: "300px",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [
      100,
      (calculateTotalInterest(
        calcState.interest / 100 / 12,
        calcState.duration * 12,
        calcState.total - calcState.dp
      ) /
        calcState.total) *
        100,
    ],
  }

  return (
    <>
      <SectionTitle
        firstWord={firstTitle}
        firstColor="light"
        secondWord={secondTitle}
        secondColor="warning"
      />
      <CustomGreyContainer>
        <Form.Group>
          <Row>
            <Col xs="12" md="6" lg="3">
              <CustomLabel htmlFor="propVal">Property Value</CustomLabel>
              <div className="input-group">
                <div className="input-group-prepend">
                  <PrependDiv className="input-group-text">
                    <h5>{currency}</h5>
                  </PrependDiv>
                </div>
                <CustomInput
                  id="total"
                  name="total"
                  type="text"
                  // value={calcState.total}
                  onChange={handleOnFormChange}
                  className="form-control"
                  style={{ borderLeft: "none" }}
                />
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <CustomLabel htmlFor="propVal">Down Payment</CustomLabel>
              <div className="input-group">
                <div className="input-group-prepend">
                  <PrependDiv className="input-group-text">
                    <h5>{currency}</h5>
                  </PrependDiv>
                </div>
                <CustomInput
                  id="dp"
                  name="dp"
                  type="text"
                  // value={calcState.dp}
                  onChange={handleOnFormChange}
                  className="form-control"
                  style={{ borderLeft: "none" }}
                />
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <CustomLabel htmlFor="">Interest Rate</CustomLabel>
              <div className="input-group">
                <CustomInput
                  id="interest"
                  name="interest"
                  type="text"
                  // value={calcState.interest}
                  onChange={handleOnFormChange}
                  className="form-control"
                  style={{ borderRight: "none" }}
                />
                <div className="input-group-append">
                  <AppendDiv className="input-group-text">
                    <h5>%</h5>
                  </AppendDiv>
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <CustomLabel htmlFor="">For</CustomLabel>
              <div className="input-group">
                <CustomInput
                  id="duration"
                  name="duration"
                  type="text"
                  // value={calcState.duration}
                  onChange={handleOnFormChange}
                  className="form-control"
                  style={{ borderRight: "none" }}
                />
                <div className="input-group-append">
                  <AppendDiv className="input-group-text">
                    <h5>years</h5>
                  </AppendDiv>
                </div>
              </div>
            </Col>
          </Row>
        </Form.Group>
      </CustomGreyContainer>
      <CustomGreyContainer className="mt-3">
        <Row>
          <Col xs="12" md="4">
            <div className="d-flex flex-column">
              <CalculatorLabelSpan>
                Property Value ({currency})
              </CalculatorLabelSpan>
              <CalculatorValueSpan className="mb-4">
                {calcState.total}
              </CalculatorValueSpan>
              <CalculatorLabelSpan>
                Total Interest ({currency})
              </CalculatorLabelSpan>
              <CalculatorValueSpan className="mb-4">
                {calculateTotalInterest(
                  calcState.interest / 100 / 12,
                  calcState.duration * 12,
                  calcState.total - calcState.dp
                ).toFixed(2)}
              </CalculatorValueSpan>
              <CalculatorLabelSpan
                style={{ fontWeight: "600", fontSize: "1rem" }}>
                Your Monthly Payment ({currency})
              </CalculatorLabelSpan>
              <CalculatorValueSpan
                style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                {calculateMonthlyPayment(
                  calcState.interest / 100 / 12,
                  calcState.duration * 12,
                  calcState.total - calcState.dp
                ).toFixed(2)}
              </CalculatorValueSpan>
              {/*  */}
            </div>
          </Col>
          <Col xs="12" md="8">
            <Chart
              type="pie"
              options={chartConfig.options}
              series={chartConfig.series}
              height="180px"
              width="100%"></Chart>
          </Col>
        </Row>
      </CustomGreyContainer>
    </>
  )
}
