import axios, { AxiosRequestConfig } from "axios"
import qs from "qs"

export const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH
// export const BASE_PATH = "https://davin-strapi-dev.herokuapp.com"
export const TORUS_BASE_PATH = process.env.NEXT_PUBLIC_TORUS_BASE_PATH

export default class HTTPServices {
  url: string = ""

  constructor(type: string) {
    if (type == "torus") {
      this.url = `${
        process.env.NEXT_PUBLIC_STRAPI_API_URL || `${TORUS_BASE_PATH}/api`
      }`
    } else {
      this.url = `${
        process.env.NEXT_PUBLIC_STRAPI_API_URL || `${BASE_PATH}/api`
      }`
    }
  }

  defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
    },
  }

  // Merge default and user options
  private mergeOption = (options): AxiosRequestConfig => {
    return {
      ...this.defaultOptions,
      ...options,
    }
  }

  private stringifyQs = (param): string => {
    return qs.stringify(param)
  }

  public get = async (path, param = {}, options = {}) => {
    const mergedOptions = this.mergeOption(options)

    const query = this.stringifyQs(param)

    const res = await axios.get(`${this.url}${path}?${query}`, mergedOptions)

    return res
  }

  public client_get = async (path, options = {}) => {
    const mergedOptions = this.mergeOption(options)

    const res = await axios.get(`${this.url}${path}`, mergedOptions)
    return res
  }

  public post = async (path, request = {}, options = {}) => {
    const mergedOptions = this.mergeOption(options)

    const res = await axios.post(`${this.url}${path}`, request, mergedOptions)
    return res
  }
}
