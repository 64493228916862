import { Row, Col, Button, Collapse } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"
import { HasCardItem } from "../HasCardItem"

import { InvestmentPropertyListModel } from "./InvestmentPropertyList.model"
import parse from "html-react-parser"
import { NoDataComponent } from "components/NoDataComponent"

export default function InvestmentPropertyList({
  dataSource,
  onCardClick,
  onClick,
}: {
  dataSource: InvestmentPropertyListModel
  onCardClick: Function
  onClick: Function
}) {
  //TODO BUSINESS LOGIC HERE

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource?.sectionTitle.firstWord}
        firstColor="primary"
        secondWord={dataSource?.sectionTitle.secondWord}
        secondColor="warning"
      />
      <p>{dataSource?.description && parse(dataSource.description)}</p>
      <div className="pt-4">
        <Row className="row-cols-2 row-cols-lg-4">
          {dataSource?.properties && dataSource?.properties.length > 0 ? (
            dataSource.properties.map(data => (
              <Col key={data.id}>
                <HasCardItem
                  id={data.id}
                  imageUrl={data.imageUrl ? `${data.imageUrl}` : ""}
                  imageAlt={data.imageAlt}
                  title={data.title}
                  urlSlug={data.title}
                  // description={data.description}
                  isImgSquare={false}
                  onClick={onCardClick}
                  projectDetails={{
                    vpDate: data.vp_date ? data.vp_date : "",
                    marketROI: data.market_roi ? data.market_roi : "",
                  }}
                />
              </Col>
            ))
          ) : (
            <Col xs="12" className="w-100">
              <NoDataComponent msg="No Properties to show" />
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <Button className="text-light" onClick={() => onClick()}>
              See more
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
