import styled from "@emotion/styled"
import { CSSProperties } from "react"

const StyledTriangleTop = styled.div`
  border-left: 33vw solid transparent !important;
  border-right: 66vw solid transparent !important;
  border-bottom: 3rem solid;
`

const StyledTriangleBottom = styled.div`
  border-right: 33vw solid transparent !important;
  border-left: 66vw solid transparent !important;
  border-top: 3rem solid;
`

interface CustomSectionProp {
  className: string
  color: string
  style?: CSSProperties
  children: any
}

export default function CustomSection({
  className,
  color,
  style,
  children,
}: CustomSectionProp) {
  return (
    <>
      {/* For triangle part */}
      <StyledTriangleTop className={`border-${color}`}></StyledTriangleTop>
      {/* For triangle part */}
      <div className={className} style={style}>
        {children}
      </div>
      {/* for bottom triangle part */}
      <StyledTriangleBottom
        className={`border-${color}`}></StyledTriangleBottom>
      {/* for bottom triangle part */}
    </>
  )
}
