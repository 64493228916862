import { SectionTitle } from "components/SectionTitle"
import { useTheme } from "@emotion/react"

//Slick
import Slider from "react-slick"
import { NextRoundArrow, PrevRoundArrow } from "components/CustomSlick"

import { PlayCircle } from "react-bootstrap-icons"
import { useState } from "react"
import VideoPlayer from "./VideoPlayer"
import { NoDataComponent } from "components/NoDataComponent"
import Image from "next/image"

const VideosCard = ({
  imageUrl,
  imageAlt,
  title,
  vidCardTitleColor,
  vidUrl,
  clickFunc,
  updVidFunc,
}) => {
  const theme = useTheme()
  return (
    <div
      onClick={() => {
        updVidFunc(vidUrl)
        clickFunc(true)
      }}>
      <div
        style={{
          aspectRatio: "3/2",
          position: "relative",
        }}>
        <Image
          src={imageUrl}
          alt={imageAlt}
          layout="fill"
          style={{ borderRadius: "1rem" }}
          objectFit="cover"
        />
        <div
          className="text-left p-4"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
          }}>
          <PlayCircle color="#FF0000" size={32} />
        </div>
      </div>
      <h4
        className="text-left mt-2 limit-1-line"
        style={{ fontWeight: "normal", color: vidCardTitleColor }}>
        {title}
      </h4>
    </div>
  )
}

export default function Videos({ titleColor, vidCardTitleColor, videoData }) {
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const settings = {
    rows: videoData.videos.length > 4 ? 2 : 1,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: <PrevRoundArrow inlineCss={{ left: "-3rem" }} />,
    nextArrow: <NextRoundArrow inlineCss={{ right: "-3rem" }} />,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevRoundArrow inlineCss={{ left: "0" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "0" }} />,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          prevArrow: <PrevRoundArrow inlineCss={{ left: "-1.5rem" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "-1.5rem" }} />,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          prevArrow: <PrevRoundArrow inlineCss={{ left: "-2rem" }} />,
          nextArrow: <NextRoundArrow inlineCss={{ right: "-2rem" }} />,
        },
      },
    ],
  }

  return (
    <div className="text-center">
      <SectionTitle
        firstWord={videoData.sectionTitle.firstWord}
        firstColor={titleColor}
      />
      {videoData.videos && videoData.videos.length > 0 ? (
        <Slider {...settings}>
          {videoData.videos.map(data => (
            <div className="p-2" key={data.id}>
              <VideosCard
                imageUrl={data.image}
                imageAlt={data.image_alt}
                title={data.video_title}
                vidCardTitleColor={vidCardTitleColor}
                vidUrl={data.video_url}
                clickFunc={setModalShow}
                updVidFunc={setVideoUrl}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <NoDataComponent msg="No Videos to show" />
      )}

      <VideoPlayer
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl={videoUrl}
      />
    </div>
  )
}
