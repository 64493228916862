import { Image } from "react-bootstrap"

//Slick
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "components/CustomSlick"
import { NoDataComponent } from "components/NoDataComponent"

export default function PropertyImage({ images }) {
  const settings = {
    prevArrow: <PrevArrow inlineCss={{ left: "2rem" }} />,
    nextArrow: <NextArrow inlineCss={{ right: "2rem" }} />,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 476,
        settings: { variableWidth: false },
      },
    ],
  }

  return (
    <div className="property-img-container">
      {images && images.length > 0 ? (
        <Slider {...settings}>
          {images.map((data, idx) => (
            <div key={`pi${idx}`}>
              <Image
                src={data.url}
                alt={""}
                style={{ width: "100%", height: "292px", objectFit: "cover" }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <NoDataComponent msg="No Images to show" />
      )}
    </div>
  )
}
