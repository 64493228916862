import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { CustomGreyContainer } from "layouts"
import Image from "next/image"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { LoanFinancialCardItemModel } from "./LoanFinancialCardItem.model"

const GreyPrimary = styled.h4(
  {
    fontWeight: "400",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const GreySecondary = styled.h5(
  {
    fontWeight: "400",
    cursor: "pointer",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

const DetailItem = styled.li(
  {
    fontSize: "0.75rem",
    textAlign: "left",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

export default function LoanFinancialCardItem({
  loanData,
  modifyList,
}: LoanFinancialCardItemModel) {
  const theme = useTheme()
  const [showMore, setShow] = useState(true)
  const [isChecked, setChecked] = useState<boolean>(false)

  const loanDetails = loanData.details?.split(";")

  return (
    <div style={{ marginBottom: "0.375rem" }}>
      <CustomGreyContainer>
        <Row>
          <Col xs="10">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <GreyPrimary style={{ fontWeight: "600" }}>
                {loanData.bank_and_lender}
              </GreyPrimary>
              <GreySecondary onClick={() => setShow(!showMore)}>
                {showMore ? "Less Details" : "More Details >"}
              </GreySecondary>
            </div>
            <Row
              style={{
                borderTop: "1px solid #00B7CE",
                display: "flex",
                paddingTop: "0.75rem",
                paddingBottom: "0.75rem",
              }}>
              <Col xs="2">
                <div style={{ maxWidth: "150px" }}>
                  <Image
                    src={loanData.img}
                    layout="responsive"
                    width={3}
                    height={1}
                    objectFit="cover"
                  />
                </div>
              </Col>
              <Col xs="10">
                <Row>
                  <Col xs="2">
                    <GreyPrimary>{loanData.monthly}</GreyPrimary>
                  </Col>
                  <Col xs="2">
                    <GreyPrimary>{loanData.interest_rate} %</GreyPrimary>
                  </Col>
                  <Col xs="2">
                    <GreyPrimary>{loanData.max_tenure} Years</GreyPrimary>
                  </Col>
                  <Col xs="2">
                    <GreyPrimary>{loanData.max_margin} %</GreyPrimary>
                  </Col>
                  <Col xs="2">
                    <GreyPrimary>{loanData.lock_in_period} Years</GreyPrimary>
                  </Col>
                  <Col xs="2">
                    <GreyPrimary>{loanData.full_flexi_loan}</GreyPrimary>
                  </Col>
                </Row>
              </Col>
            </Row>
            {showMore && (
              <Row style={{ borderTop: "1px dashed #00B7CE" }}>
                <div className="d-flex flex-column align-items-start pt-2">
                  <GreySecondary style={{ fontWeight: "600" }}>
                    More About This Product
                  </GreySecondary>
                  <ul>
                    {loanDetails?.length > 0 &&
                      loanDetails.map((val, idx) => {
                        if (val != "") {
                          return <DetailItem key={`ld${idx}`}>{val}</DetailItem>
                        }
                      })}
                  </ul>
                </div>
              </Row>
            )}
          </Col>
          <Col
            xs="2"
            className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              style={{
                width: "2rem",
                height: "2rem",
                border: `3px solid ${theme.colors.primary}`,
              }}
              onChange={() => {
                if (isChecked == true) {
                  setChecked(false)
                  modifyList(loanData, false)
                } else {
                  setChecked(true)
                  modifyList(loanData, true)
                }
              }}
              checked={isChecked}
            />
          </Col>
        </Row>
      </CustomGreyContainer>
    </div>
  )
}
