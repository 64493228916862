import { useTheme } from "@emotion/react"
import { Col, Dropdown, DropdownButton, Image, Row } from "react-bootstrap"

export default function FilterComp({ btnImgSrc, filterObj, setFilterObj, colLength }) {
  const theme = useTheme()

  return (
    <div className="d-flex justify-content-end pt-2 pb-5">
      <div
        style={{
          backgroundColor: theme.colors.greybright,
          borderRadius: "0.25rem",
        }}
        className="d-flex align-items-center p-2">
        <div id="filtercontainer">
          <Row>
            {filterObj.length > 0 &&
              filterObj.map((filter_data, filter_idx) => (
                <Col
                  xs={colLength}
                  key={`fltlbl${filter_idx}`}
                  style={
                    filter_idx != 0
                      ? {
                          borderLeft: `1px solid ${theme.colors.secondary}`,
                        }
                      : {}
                  }>
                  <div style={{ height: "100%" }}>
                    <p style={{ margin: "0", textAlign: "start" }}>
                      {filter_data.filter_name}
                    </p>
                  </div>
                </Col>
              ))}
            {filterObj.length > 0 &&
              filterObj.map((filter_data, filter_idx) => (
                <Col
                  xs={colLength}
                  key={`fltbtn${filter_idx}`}
                  style={
                    filter_idx != 0
                      ? {
                          borderLeft: `1px solid ${theme.colors.secondary}`,
                        }
                      : {}
                  }>
                  <div style={{ height: "100%" }}>
                    <DropdownButton
                      id="dropd-filter-btn"
                      title={filter_data.filter_value.name}
                      align="end"
                      style={{ width: "100%" }}>
                      {filter_data.filter_selections.length > 0 &&
                        filter_data.filter_selections.map(selection_data => (
                          <Dropdown.Item
                            key={selection_data.value}
                            onClick={() => {
                              let newState = JSON.parse(
                                JSON.stringify(filterObj)
                              )
                              newState[filter_idx].filter_value = selection_data
                              setFilterObj(newState)
                            }}>
                            {selection_data.name}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
        <button className="btn btn-primary h-100">
          <Image src={btnImgSrc} alt="" style={{ height: "1rem" }} />
        </button>
      </div>
    </div>
  )
}
