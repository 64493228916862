import { Col, Row, Image } from "react-bootstrap"
import { SectionTitle } from "components/SectionTitle"
import { HasOtaPartnersModel } from "./HasOtaPartners.model"

//Slick
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "components/CustomSlick"
import { BASE_PATH } from "@shared/services/HttpServices"
import { NoDataComponent } from "components/NoDataComponent"

export default function HasOtaPartners({
  dataSource,
}: {
  dataSource: HasOtaPartnersModel
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          prevArrow: <PrevArrow inlineCss={{ left: "1.5rem" }} />,
          nextArrow: <NextArrow inlineCss={{ right: "1.5rem" }} />,
        },
      },
    ],
  }
  return (
    <div className="text-center">
      <SectionTitle
        firstWord={dataSource.sectionTitle.firstWord}
        firstColor={dataSource.sectionTitle.firstColor}
        secondWord={dataSource.sectionTitle.secondWord}
        secondColor={dataSource.sectionTitle.secondColor}
      />
      <div className="mt-5 has-ota-partners-list">
        {dataSource.otaPartners && dataSource.otaPartners.length > 0 ? (
          <Slider {...settings}>
            {dataSource.otaPartners.map(data => (
              <div className="p-2" key={data.id}>
                <Image
                  src={`${BASE_PATH}${data.image_url}`}
                  alt={data.image_alt}
                  className="w-100"
                />
              </div>
            ))}
          </Slider>
        ) : (
          <NoDataComponent msg="No OTA Partners to show" />
        )}
      </div>
    </div>
  )
}
