import { Image, Button } from "react-bootstrap"
import { useTheme } from "@emotion/react"

export default function PropertyDeveloper({ developer }) {
  const theme = useTheme()
  const signupRedirect = "https://torus.hostastay.com/sign-up"

  return (
    <div
      className="text-center p-4"
      style={{
        backgroundColor: theme.colors.greybright,
      }}>
      <div className="mb-3">
        <Image
          alt={""}
          style={{
            width: "6rem",
          }}
          src={developer?.avatar_url}
        />
      </div>
      <div className="developer-info-title">
        <h5
          style={{
            fontWeight: 600,
          }}
          className="mb-0">
          {developer?.name}
        </h5>
      </div>
      <div className="developer-info-website-url mb-5">
        <p style={{ color: theme.colors.secondary, wordWrap: "break-word" }}>
          {developer?.website}
        </p>
      </div>
      <h5
        style={{
          fontWeight: 600,
        }}>
        Interested with this property?
      </h5>
      <div className="developer-signup-btn">
        <Button
          className="btn text-light fw-normal px-4 mt-3"
          onClick={() => {
            window.location.href = signupRedirect
          }}>
          Sign Up Now!
        </Button>
      </div>
    </div>
  )
}
