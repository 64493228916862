import { Nav, Tab, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"

const StyledNav = styled(Nav)(
  {
    fontSize: "0.875rem",
    "& a": {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: ".25rem",
      marginRight: "1rem",
      cursor: "Pointer",
    },
    "@media (min-width: 992px)": {
      fontSize: "1rem",
    },
  },
  ({ theme }) => ({
    "& a": {
      color: theme.colors.dark,
      borderBottom: "2px solid transparent",
    },
    "& a.active": {
      color: theme.colors.primary,
      borderBottomColor: theme.colors.primary,
    },
  })
)

const StyledUl = styled.ul(
  {
    paddingLeft: "1.5rem",
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)

export default function PropertyFacilityAmenity({ additional_informations }) {
  return (
    <div>
      <h4 className="mb-4" style={{ fontWeight: 500 }}>
        Facilities &amp; Amenities
      </h4>
      {additional_informations && (
        <div>
          <Tab.Container
            defaultActiveKey={
              additional_informations.length > 0 &&
              additional_informations[0].id
            }
            id="uncontrolled-tab">
            <Row>
              <Col xs={12}>
                <StyledNav className="pb-3">
                  {additional_informations &&
                    additional_informations.map(data => (
                      <Nav.Item key={data.id}>
                        <Nav.Link eventKey={data.id}>{data.title}</Nav.Link>
                      </Nav.Item>
                    ))}
                </StyledNav>
              </Col>
              <Col xs={12}>
                <Tab.Content>
                  {additional_informations &&
                    additional_informations.map(data => (
                      <Tab.Pane eventKey={data.id} key={data.id}>
                        <StyledUl>
                          {data.items &&
                            data.items.map((item, idx) => (
                              <li key={idx}>
                                <p>{item}</p>
                              </li>
                            ))}
                        </StyledUl>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </div>
  )
}
